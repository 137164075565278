import React, { useEffect, useState } from 'react';
import { Route, useNavigate, Routes } from 'react-router-dom';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { t } from '@lingui/macro';

import { clearCart, setCart, setItems, setLoading, setPlayer, setStripeKey, updateAuth } from '../store/actions';
import { useAppState } from '../store/store';
import { IAuth, IItem, IPurchase, ITransaction, IPricing, IPlayer } from '../types/';
import { getAppById, HandleError, setBodyClass, currentAppName, hirAppId } from '../helpers/Utils';
import Api from '../helpers/Api';
import { IApiError } from '../helpers/Ajax';
import { IApp } from '../helpers/Apps';
import CONFIG from '../helpers/Config';
import { Track, UserFunnelActions } from '../helpers/Track';

import DialogBox from './common/DialogBox';
import Cart from './Cart';
import Receipt from './Receipt';

const PokerCatalog = React.lazy(() => import('./Catalog/Poker'));
const EmpirePuzzleCatalog = React.lazy(() => import('./Catalog/EmpirePuzzles'));
const HitItRichCatalog = React.lazy(() => import('./Catalog/HitItRich'));

const Game = () => {
  const { state, dispatch } = useAppState();
  const navigate = useNavigate();

  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(null!);
  const [currentApp, setCurrentApp] = useState<IApp>();
  const { stripeKey, auth, player } = state;

  const handleError = (error: IApiError) => {
    if (auth?.appId) {
      HandleError({ error, appId: auth?.appId, navigate, dispatch });
      dispatch(setLoading(false));
    }
  };

  const handleOFCAErrors = async ({ purchase, cart }: { purchase: IPurchase; cart: IItem }) => {
    let error;
    if (!purchase?.availability_result?.success) {
      error = { category: 'out_of_stock', message: 'out_of_stock' };
    } else if (
      !purchase?.screen_result?.success ||
      !purchase?.custom_attributes_result?.success ||
      !purchase?.location_result?.success ||
      !purchase?.auth_result.success
    ) {
      error = { category: 'generic_error' };
    }
    if (error) {
      if (auth?.appId) {
        HandleError({ error, appId: auth?.appId, navigate, dispatch });
        dispatch(setLoading(false));
      }
    } else {
      dispatch(setCart({ appId: auth?.appId as string, cart, purchase }));
      await loadStoreData(true);
      navigate('cart');
      dispatch(setLoading(false));
      return;
    }
  };

  const onGameLoad = () => {
    if (auth) {
      const app = getAppById(auth.appId);
      setCurrentApp(app);
      app && setBodyClass('game-', [auth.appId, app!.pathname, app.className]);
    }
    if (auth && !stripeKey) {
      loadStoreData();
    }
  };

  const loadStoreData = async (udpateBalanceOnly?: boolean) => {
    dispatch(setLoading(true));
    return Api.initStore(auth!).then(
      (res) => {
        if (!stripeKey) {
          dispatch(setStripeKey(res.stripeKey));
          setStripePromise(loadStripe(res.stripeKey));
        }
        dispatch(setPlayer(res.playerInformation));
        if (!udpateBalanceOnly) {
          const newAuth: IAuth = { ...auth! };
          if (res.playerInformation.currency && newAuth) {
            newAuth.currency = res.playerInformation.currency;
          }
          if (res.playerInformation.country_of_purchase && newAuth) {
            newAuth.countryOfPurchase = res.playerInformation.country_of_purchase;
          }
          dispatch(updateAuth({ appId: auth!.appId, auth: newAuth }));
          dispatch(setItems(res.products));
        }
        dispatch(setLoading(false));
        return true;
      },
      (error: IApiError) => {
        handleError(error);
        return true;
      }
    );
  };

  useEffect(() => {
    navigate('catalog', { replace: true });
  }, []);

  useEffect(() => {
    const unsubscribe = onGameLoad();
    // added to avoid memory leaks on "/store" screen
    return () => unsubscribe;
  }, [auth]);

  const onAddToCart = (productId: string): void => {
    const cart = state.items?.find((x) => x.product_id === productId);

    if (cart) {
      dispatch(setLoading(true));
      Api.initPurchase({ ...(state.auth as IAuth), productId }).then((purchase) => {
        handleOFCAErrors({ purchase, cart });
      }, handleError);
    }
  };

  const onComplete = (txn: ITransaction & { vendor: string; brand: string; pricing?: IPricing }) => {
    const item = (state.items as IItem[]).find((x) => x.product_id === txn.transaction.product_id);
    if (!item) {
      return;
    }

    const app = getAppById(txn.transaction.app_id);

    dispatch(clearCart());
    navigate('receipt', {
      state: {
        name: item.name,
        description: item.description,
        iconUrl: item.icon_url,
        orderId: txn.transaction.transaction_identifier,
        displayPrice: txn.pricing?.total,
        productId: item.product_id,
        purchaserEmail: txn.purchaserEmail,
        brand: txn.brand,
        contents: item.contents,
        id: txn.transaction.transaction_identifier
      }
    });
    dispatch(setLoading(false));
  };

  return (
    <div className='game'>
      {state?.error && <DialogBox message={t({ id: state?.error?.message })} />}
      <Routes>
        <Route
          path='catalog'
          element={
            currentAppName(currentApp) === CONFIG.appsName.poker ? (
              <PokerCatalog items={state.items} onAddToCart={onAddToCart} />
            ) : currentAppName(currentApp) === CONFIG.appsName.empire_and_puzzle ? (
              <EmpirePuzzleCatalog items={state.items} onAddToCart={onAddToCart} />
            ) : (
              <HitItRichCatalog items={state.items} onAddToCart={onAddToCart} />
            )
          }
        />
        <Route
          path='cart'
          element={
            <Cart
              stripePromise={stripePromise}
              purchase={state.purchase}
              cart={state.cart}
              onComplete={onComplete}
              token={state.auth && state.auth.token}
              locale={state.auth && state.auth.locale}
              handleOFCAErrors={handleOFCAErrors}
              syncStoreData={loadStoreData}
            />
          }
        />
        <Route path='receipt' element={<Receipt syncStoreData={loadStoreData} />} />
      </Routes>
    </div>
  );
};

export default Game;
