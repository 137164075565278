import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import '@ungap/global-this';

import './index.scss';
import './styles/index.tsx';
import App from './App';
import { AppStateProvider } from './store/store';

// Only load the app when it is loaded at the top level
if (window.self !== window.top) {
  ReactDOM.render(
    <React.StrictMode>
      <div></div>
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <AppStateProvider>
          <Routes>
            <Route path='/*' element={<App />} />
          </Routes>
        </AppStateProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
