import Log from './Log';

const ls = window.localStorage;

type IStoreValue = any;

const getItem = (key: string): IStoreValue | null => {
  if (!ls) {
    Log.error('localeStorage is not available');
    return null;
  }
  const str = ls.getItem(key);
  try {
    return str !== null ? JSON.parse(str) : null;
  } catch (error) {
    return null;
  }
};
const setItem = (key: string, value: IStoreValue): true | null => {
  if (!ls) {
    Log.error('localeStorage is not available');
    return null;
  }
  try {
    const serializedValue = JSON.stringify(value);
    ls.setItem(key, serializedValue);
    return true;
  } catch (error) {
    return null;
  }
};
const deleteItem = (key: string): true | null => {
  if (!ls) {
    Log.error('localeStorage is not available');
    return null;
  }
  ls.removeItem(key);
  return true;
};
const LocalStorage = { getItem, setItem, deleteItem };

export default LocalStorage;
