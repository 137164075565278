import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import { useAppState } from '../store/store';
import { IAuth } from '../types/Auth';

import Game from './Game';
import { getAppById } from '../helpers/Utils';

const Store = () => {
  const { state } = useAppState();
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const location = useLocation();

  const gamePath = location.pathname.split('/')[1];

  const appId = (state?.auth as IAuth)?.appId;

  useEffect(() => {
    const app = getAppById(appId);
    if (app) {
      const isPathSame = gamePath === app.pathname && app.appId === state.currentAppId;
      setIsReady(isPathSame);
      if (!isPathSame) {
        navigate(app.pathname + '/catalog', { replace: true });
      }
    }
  }, [appId]);

  useEffect(() => {
    const app = getAppById(appId);
    if (app) {
      const isPathSame = gamePath === app.pathname && app.appId === state.currentAppId;
      setIsReady(isPathSame);
    }
  }, [appId, gamePath, state.currentAppId]);

  return (
    <Routes>
      <Route path=':gameName/*' element={isReady ? <Game /> : <></>} />
    </Routes>
  );
};
export default Store;
