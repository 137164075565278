import React from 'react';
import { Trans } from '@lingui/macro';

import { useAppState } from '../../store/store';
import { NumberFormat } from '../../helpers/formatter';
import { IPlayer } from '../../types';
import { POKER_CURRENCY } from '../../helpers/metaData/poker';

const GameBalance = () => {
  const { player } = useAppState().state;
  const { balances = [] } = { ...player } as IPlayer;

  const isVipTierPresent = () => balances?.some((currency) => currency.key === POKER_CURRENCY.club_tier);

  return (
    <div className='balance-wrapper'>
      <div className='max-w-sm mx-auto flex in-game-balance'>
        {!isVipTierPresent() && (
          <div className='balance-title'>
            <Trans id='balance_title'>In-game Balance:</Trans>
          </div>
        )}
        <div className='individual-balances-wrapper justify-around'>
          {balances.map((currency, index) => (
            <div className='individual-balances' key={index}>
              {currency.key === POKER_CURRENCY.club_tier && (
                <div className='current-amount'>{currency.currency_name}</div>
              )}
              <img src={currency.icon_url} alt={currency.currency_name} width={25} className='currency-icon' />
              {currency.key !== POKER_CURRENCY.club_tier && (
                <div
                  className={`current-amount ${
                    currency.amount && currency.amount.toString().length > 12 ? 'compact' : ''
                  }`}
                >
                  {NumberFormat(currency.amount) || 0}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GameBalance;
