import React, { useState, useEffect } from 'react';
import braintree, { PayPalCheckout } from 'braintree-web';
/* eslint-disable-next-line */
import { PayPalNamespace } from '@paypal/paypal-js';
import { PayPalCheckoutTokenizationOptions } from 'braintree-web/modules/paypal-checkout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcPaypal } from '@fortawesome/free-brands-svg-icons';
import { Trans } from '@lingui/macro';

import { useAppState } from '../store/store';
import { setLoading } from '../store/actions';
import { IPricing } from '../types/Purchase';
import { g, GAEvents } from '../helpers/GoogleAnalytics';

import { Button } from './common/Buttons';
import Spinner from './common/Spinner';

type IPayPalProps = {
  onPaypalPayment: (nonce: string) => void;
  pricing: IPricing;
  authorization: string;
  onCartError: (err?: string) => void;
};

const PayPal = (props: IPayPalProps) => {
  const [paypalInstance, setPaypalInstance] = useState<PayPalCheckout | undefined>(undefined);
  const [loadingPP, setLoadingPP] = useState(false);

  const { onPaypalPayment, authorization, pricing } = props;
  const { state, dispatch } = useAppState();

  useEffect(() => {
    const t0 = Date.now();
    const logger = (msg) => {};
    const initPaypal = () => console.log(braintree.client.VERSION);
    setLoadingPP(true);
    braintree.client
      .create({
        authorization: authorization
      })
      .then((client) => {
        logger('clientCreate ');
        braintree.paypalCheckout.create(
          {
            client
          },
          async (err, ppClient: PayPalCheckout) => {
            logger('checkout create ');
            // const clientId = await ppClient.getClientId();
            logger('got client id');
            if (err) {
              console.error(err);
            } else {
              ppClient
                .loadPayPalSDK({
                  // 'client-id': clientId,
                  currency: pricing.currency
                })
                .then(() => {
                  logger('loadPayPalSDK ');
                  setPaypalInstance(ppClient);
                  setLoadingPP(false);

                  return window.paypal
                    .Buttons?.({
                      style: {
                        label: 'paypal',
                        shape: 'rect',
                        tagline: false,
                        color: 'gold',
                        height: 50
                      },
                      fundingSource: window.paypal.FUNDING.PAYPAL.toString(),
                      onInit: () => {
                        logger('btn init');
                      },
                      createOrder: () => {
                        return ppClient.createPayment({
                          flow: 'checkout' as paypal.FlowType.Checkout,
                          amount: pricing.charge_amount,
                          currency: pricing.currency
                        });
                      },
                      onApprove: (data) => {
                        // annoying ts hack
                        const res = data as unknown as PayPalCheckoutTokenizationOptions;
                        return ppClient.tokenizePayment(res).then((payload) => {
                          onPaypalPayment(payload.nonce);
                        });
                      },
                      onClick: () => {
                        props.onCartError(undefined);
                        dispatch(setLoading(true));
                      },
                      onCancel: function (data) {
                        dispatch(setLoading(false));
                      },
                      onError: function (err) {
                        dispatch(setLoading(false));
                      }
                    })
                    .render('#pp-button')
                    .then(() => {
                      logger('rendered!');
                    });
                });
            }
          }
        );
      })
      .catch((err) => {});
    if (paypalInstance) {
      paypalInstance.teardown(() => {
        initPaypal();
      });
    } else {
      initPaypal();
    }
  }, []);

  return (
    <>
      <div id='pp-button' className='w-full max-w-sm z-0  pp-wrapper absolute' />
      {loadingPP && (
        <div className='button-loading'>
          <Spinner show={true} local={true} />
        </div>
      )}
    </>
  );
};

export default PayPal;
