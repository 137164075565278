import Log from './Log';

export type IApiError = {
  url?: string;
  status?: number;
  statusText?: string;
  category?: number | string;
  message?: string;
  details?: string;
};

const makeError = (err: IApiError): IApiError => {
  return {
    url: err.url,
    status: err.status,
    statusText: err.statusText,
    category: err.category,
    message: err.message,
    details: err.details
  };
};

const Ajax = (url: string, options?: RequestInit, data?: object | string) => {
  return fetch(url, options)
    .then(
      (res: Response) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then(
            (body) => {
              if (Array.isArray(body)) {
                throw makeError({
                  url,
                  status: res.status,
                  statusText: res.statusText,
                  category: body[0].errorCode,
                  message: body[0].errorMessage
                });
              } else {
                throw makeError({
                  url,
                  status: res.status,
                  statusText: res.statusText,
                  category: body[0]?.errorCode,
                  message: body[0]?.errorMessage
                });
              }
            },
            () => {
              throw makeError({
                url,
                status: res.status,
                statusText: res.statusText,
                category: res.headers.get('error-category') || res.status,
                message: res.headers.get('error-message') || res.statusText
              });
            }
          );
        }
      },
      // catch offline and cors issues
      (rej) => {
        Log.error(rej);
        throw makeError({
          url,
          status: 999,
          statusText: 'Fetch failed',
          message: rej
        });
      }
    )
    .catch((err: IApiError) => {
      Log.error(err);
      return Promise.reject(err);
    });
};

export default Ajax;
