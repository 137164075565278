import stripeLocaleCodes from './StripeLocaleCodes';
import apps from './Apps';

export enum UniversalLinkType {
  open = 'open',
  close = 'close'
}

const trackEvent = 'zstore';
const trackClientId = '495';

const UniversalLinks = {
  [UniversalLinkType.open]: 'store-open',
  [UniversalLinkType.close]: 'store-return',
  'store-open': UniversalLinkType.open,
  'store-return': UniversalLinkType.close
};

const paypalCurrencies = [
  'AUD',
  'BRL',
  'CAD',
  'CNY',
  'CZK',
  'DKK',
  'EUR',
  'HKD',
  'HUF',
  'ILS',
  'JPY',
  'MYR',
  'MXN',
  'TWD',
  'NZD',
  'NOK',
  'PHP',
  'PLN',
  'GBP',
  'RUB',
  'SGD',
  'SEK',
  'CHF',
  'THB',
  'USD'
];
const applePayCountries = [
  'AE',
  'AT',
  'AU',
  'BE',
  'BG',
  'BR',
  'CA',
  'CH',
  'CI',
  'CR',
  'CY',
  'CZ',
  'DE',
  'DK',
  'DO',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GI',
  'GR',
  'GT',
  'HK',
  'HR',
  'HU',
  'ID',
  'IE',
  'IN',
  'IT',
  'JP',
  'LI',
  'LT',
  'LU',
  'LV',
  'MT',
  'MX',
  'MY',
  'NL',
  'NO',
  'NZ',
  'PE',
  'PH',
  'PL',
  'PT',
  'RO',
  'SE',
  'SG',
  'SI',
  'SK',
  'SN',
  'TH',
  'TT',
  'US',
  'UY'
];

const brands = {
  amex: 'AMEX',
  diners: 'Diners',
  discover: 'Discover',
  jcb: 'JCB',
  mastercard: 'Mastercard',
  unionpay: 'UnionPay',
  visa: 'VISA',
  unknown: 'Unknown',
  paypal: 'PayPal',
  creditcard: 'Credit card',
  applepay: 'Apple Pay',
  googlepay: 'Google Pay'
};

const storeHost = process.env.REACT_APP_UNIV_HOST
  ? (process.env.REACT_APP_UNIV_HOST as string)
  : document.location.origin;

const apiErrors: { [key: string]: string } = {
  authInvalid: 'error_authInvalid',
  not_authorize: 'error_not_authorised',
  generic_error: 'error_generic',
  duuid_mismatch_error: 'error_duuid_mismatch'
};

export const appClassnames = {
  poker: 'poker',
  e_and_p: 'e-and-p',
  hit_it_rich: 'hit-it-rich'
};

const appsName = {
  empire_and_puzzle: 'Empires & Puzzles',
  poker: 'Poker',
  hit_it_rich: 'Hit it Rich',
  words_with_friends: 'words-with-friends',
  wizard_of_oz_slots: 'wizard-of-oz-slots',
  merge_dragons: 'merge-dragons',
  dragon_city: 'dragon-city'
};

const marketingMessages = ['bestvalue', 'dailyfreebie', 'mostpopular'];
export const pokerFreeChipsUrl = process.env.REACT_APP_POKER_CHIPS_URL || '';

const refundPolicyUrl = 'https://zynga.support/ZyngaStoreRefundPolicy';

const europeCountriesCode = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'EL',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'NO',
  'IS',
  'LI'
];

const c = {
  zyngaStore: 'zynga_store',
  errors: apiErrors,
  iapHost: process.env.REACT_APP_API_URL as string,
  iapPath: process.env.REACT_APP_IAP_API as string,
  clientId: 495,
  universalLink: {
    host: storeHost,
    ...UniversalLinks
  },
  duuid: {
    name: 'duuid',
    duration: 30 * 60 // in seconds (30 min)
  },
  currentYear: new Date().getFullYear(),
  // these are the codes that Stripe will accept
  stripeLocaleCodes,
  appVersion: process.env.REACT_APP_VERSION as string,
  defaultAppId: process.env.REACT_APP_DEFAULT_APP_ID,
  apps: apps,
  brands,
  appClassnames,
  trackEvent,
  trackClientId,
  paypalCurrencies,
  applePayCountries,
  appsName,
  marketingMessages,
  refundPolicyUrl,
  europeCountriesCode
};

const CONFIG = Object.freeze(c);

export default CONFIG;
