import { IAuth, IPlayer, ICustomProduct } from '../types';
import { IItem } from '../types/Catalog';
import { IPurchase } from '../types/Purchase';

export const SET_LOADING = 'SET_LOADING';
export const SET_STRIPE_KEY = 'SET_STRIPE_KEY';
export const SET_AUTH = 'SET_AUTH';
export const UPDATE_AUTH = 'UPDATE_AUTH';
export const CLEAR_AUTH = 'CLEAR_AUTH';
export const SET_CATALOG = 'SET_CATALOG';
export const SET_ITEMS = 'SET_ITEMS';
export const SET_CART = 'SET_CART';
export const SET_PURCHASE = 'SET_PURCHASE';
export const CLEAR_CART = 'CLEAR_CART';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PLAYER = 'SET_PLAYER';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAN_APP_STATE = 'CLEAR_APP_STATE';

export type IAction = {
  type: string;
  payload?: any;
};

export const setLoading = (isLoading: boolean): IAction => ({
  type: SET_LOADING,
  payload: isLoading
});

export const clearAppState = (appId: string) => ({
  type: CLEAN_APP_STATE,
  payload: appId
});

export const setStripeKey = (stripeKey: string): IAction => ({
  type: SET_STRIPE_KEY,
  payload: stripeKey
});

export const setAuth = (auth: IAuth): IAction => ({
  type: SET_AUTH,
  payload: auth
});

export const updateAuth = ({ appId, auth }: { appId: string; auth: Partial<IAuth> }): IAction => ({
  type: UPDATE_AUTH,
  payload: { appId, auth }
});

export const clearAuth = (appId: string): IAction => ({
  type: CLEAR_AUTH,
  payload: appId
});

export const setCart = ({ appId, cart, purchase }: { appId: string; cart: IItem; purchase: IPurchase }): IAction => ({
  type: SET_CART,
  payload: { appId, cart, purchase }
});

export const clearCart = (): IAction => ({
  type: CLEAR_CART
});

export const setItems = (items: ICustomProduct[]): IAction => ({
  type: SET_ITEMS,
  payload: items
});

export const setPlayer = (player: IPlayer): IAction => ({
  type: SET_PLAYER,
  payload: player
});

export const setError = ({ message }: { message: string }): IAction => ({
  type: SET_ERROR,
  payload: { error: { message } }
});

export const clearError = (): IAction => ({
  type: CLEAR_ERROR
});
