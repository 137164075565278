import React, { useState, useEffect } from 'react';
import '../../styles/banner.scss';
export const BANNER_CONFIG = {
  type: 'TOS',
  mainText: 'Our legal terms are changing on February 28, 2024. ',
  linkText: 'TERMS OF SERVICE',
  link: 'https://www.take2games.com/legal/en-US',
  textColor: '#222c37',
  bgColor: '#dfa749',
  startDate: '29 Jan 2024 00:00:00',
  endDate: '27 Feb 2024 23:59:59'
};

const Banner = () => {
  const [isShow, setIsShow] = useState(false);
  const banner = BANNER_CONFIG;
  useEffect(() => {
    const now = Date.now();
    const start = Date.parse(banner.startDate);
    const end = Date.parse(banner.endDate);
    if (start < now && now < end) {
      setIsShow(true);
    }
  }, []);

  if (isShow) {
    return (
      <section className='fixed top-0 left-0 right-0 z-10 px-5 flex justify-center items-center notification-banner-container'>
        <div className='flex justify-center items-center flex-wrap'>
          <p className='text-center inline'>
            {banner.mainText}
            <a className='underline font-bold inline' target='_blank' href={banner.link} rel='noopener noreferrer'>
              {banner.linkText}
            </a>
          </p>
        </div>
      </section>
    );
  }
  return <></>;
};
export default Banner;
