import { t } from '@lingui/macro';

import { IApp } from '../../helpers/Apps';

import apple from '../../images/icons/apple.svg';
import google from '../../images/icons/google-play.png';
import amazon from '../../images/icons/amazon-button.png';

type IDownloadButtonTypesProps = {
  type: string;
  className: string;
  app: IApp;
};

export const DownloadButtonTypes = {
  APPLE: 'APPLE',
  GOOGLE_PLAY: 'GOOGLE_PLAY',
  AMAZON: 'AMAZON'
};

export const DownloadButton = ({ type, className = '', app }: IDownloadButtonTypesProps) => {
  switch (type) {
    case DownloadButtonTypes.APPLE:
      return (
        <a href={app.appleStoreLink} target='_blank' rel='noopener noreferrer'>
          <img
            src={apple}
            alt={t({ id: 'download_ios', message: `Download ${app.displayName} on iOS` })}
            className={`h-50 lg:h-60 ${className}`}
          />
        </a>
      );
    case DownloadButtonTypes.GOOGLE_PLAY:
      return (
        <a href={app.googlePlayLink} target='_blank' rel='noopener noreferrer'>
          <img
            src={google}
            className={`g-play-button ${className}`}
            alt={t({ id: 'download_android', message: `Download ${app.displayName} on Google Play` })}
          />
        </a>
      );
    case DownloadButtonTypes.AMAZON:
      return (
        <a href={app.amazonStoreLink} target='_blank' rel='noopener noreferrer'>
          <img
            src={amazon}
            className={`amazon-button ${className}`}
            alt={t({ id: 'download_amazon', message: `Download ${app.displayName} from Amazon` })}
          />
        </a>
      );
    default:
      return <></>;
  }
};
