/**
 * NOTE:
 * This is a placeholder for eventually logging/tracking errors.
 */

const Log = {
  error: (...args: any) => {
    window.console.error(...args);
  },
  warn: (...args: any) => {
    window.console.warn(...args);
  },
  log: (...args: any) => {
    window.console.log(...args);
  },
  debug: (...args: any) => {
    window.console.debug(...args);
  },
}

export default Log;
