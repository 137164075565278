import { useRef, Suspense, useEffect, useState, createContext } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { i18nConfig } from './i18n';
import { useAppState } from './store/store';
import { clearAppState, setAuth, setLoading } from './store/actions';
import AuthStorage from './helpers/AuthStorage';
import Api from './helpers/Api';
import {
  devLogger,
  getAppById,
  getAppFromPath,
  getAuthedApps,
  getDeviceId,
  HandleError,
  isCurrentAppAuthed,
  setFreshDuuid
} from './helpers/Utils';
import { isSupportedDevice } from './helpers/device';
import { Track } from './helpers/Track';
import Log from './helpers/Log';
import { g, GAEvents } from './helpers/GoogleAnalytics';
import CONFIG from './helpers/Config';

import Spinner from './views/common/Spinner';
import { SidebarMenuProvider } from './views/common/SideBarMenuContext';
import Header from './views/common/Header';
import Store from './views/Store';
import LandingWrapper from './views/LandingWrapper';

export const WrapContext = createContext((): number | undefined => {
  return;
});

const getParamsFromSearch = (searchParams: URLSearchParams): { [key: string]: string } => {
  let p: { [key: string]: string } = {};
  searchParams.forEach((value, key) => {
    p[key] = value;
  });
  devLogger.log(p);
  return p;
};
const getAuthFromParams = (params: {
  [key: string]: string;
}): {
  token: string;
  currency: string;
  locale: string;
  countryOfPurchase: string;
  duuid?: string;
  appLoadId?: string;
  appVersion?: string;
} | void => {
  if (params.token && (process.env.REACT_APP_ALLOW_WITHOUT_DUUID === 'true' || params.duuid)) {
    return {
      token: params.token,
      currency: params.currency || 'USD',
      locale: params.locale || 'en_US',
      appLoadId: params.zt,
      appVersion: params.app_version,
      duuid: params.duuid,
      countryOfPurchase: params.country_of_purchase || ''
    };
  }
};
const getAuthFromLocation = (location: Partial<Location>) => {
  const paramsAuth = getAuthFromParams(getParamsFromSearch(new URLSearchParams(location.search)));
  const fullPath = location.pathname && location.pathname.split('/');
  devLogger.log(location.pathname, location.search?.substring(1));
  const gameIdentifier = fullPath && fullPath[1];
  const app = getAppFromPath(gameIdentifier);
  // is this a valid app identifier?
  if (!app || !paramsAuth) {
    return;
  }
  return {
    ...paramsAuth,
    appId: app.appId
  };
};
const gameExists = (appId: string | number) => {
  return !!getAppById(appId);
};

const App = () => {
  const { state, dispatch } = useAppState();
  const [isReady, setIsReady] = useState(false);
  const navigate = useNavigate();
  const wrapRef = useRef<HTMLDivElement>(null);
  const scrollToTop = () => {
    if (wrapRef && wrapRef.current) {
      return setTimeout(() => {
        wrapRef.current &&
          wrapRef.current.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
      });
    }
  };

  const location = useLocation();
  const pathname = location.pathname;

  const app = getAppFromPath(pathname);

  const zt = new URLSearchParams(location.search).get('zt');
  if (zt) {
    Track.appLoadId = zt;
  }

  const authStore = getAppFromPath(pathname) && AuthStorage.get(getAppFromPath(pathname)!.appId);

  useEffect(() => {
    const localStorageChange = (evt: StorageEvent): any => {
      const path = document.location.pathname;
      const currentApp = getAppFromPath(path);
      if (currentApp && getAuthedApps().indexOf(currentApp.appId) === -1 && evt.key === 'auth-' + currentApp.appId) {
        dispatch(clearAppState(currentApp.appId));
        navigate(`/${currentApp.pathname}`);
      }
    };
    window.addEventListener('storage', localStorageChange);
    return () => {
      window.removeEventListener('storage', localStorageChange);
    };
  }, []);
  useEffect(() => {
    i18nConfig.dynamicActivate(i18nConfig.getLocale());
    g.sendEvent(GAEvents.pageLoad, { path: location.pathname, appId: app?.appId });
    const duuid = getDeviceId();
    const isNewApp = app && app.appId !== state.currentAppId;

    if (isNewApp && isSupportedDevice() && app) {
      dispatch(clearAppState(app.appId));
      dispatch(setLoading(true));
      const authParams = getAuthFromLocation(location);

      if (authParams && process.env.REACT_APP_ALLOW_WITHOUT_DUUID !== 'true' && authParams.duuid !== duuid) {
        Log.debug(`duuid mismatch, stored = ${duuid}, received = ${authParams?.duuid}`);
        g.sendEvent(GAEvents.authFailure, { reason: 'duuid mismatch', appId: app.appId });

        const error = {
          category: CONFIG.errors.duuid_mismatch_error,
          message: `${t({ id: CONFIG.errors.duuid_mismatch_error })}`
        };
        HandleError({ error, appId: authParams.appId, navigate, dispatch });

        setIsReady(true);
        dispatch(setLoading(false));
      } else if (authParams && authParams.appId && gameExists(authParams?.appId) && authParams.token) {
        Api.checkAuth(authParams.token)
          .then(
            (authRes) => {
              delete authParams.duuid;
              setFreshDuuid();
              dispatch(
                setAuth({
                  ...authParams,
                  appLoadId: authParams.appLoadId || Track.appLoadId,
                  playerId: authRes.playerId,
                  expiration: authRes.expiration
                })
              );
              g.sendEvent(GAEvents.authSuccess, {
                source: 'auth params',
                appId: authParams.appId
              });
            },
            (rej) => {
              if (authStore && authStore.expiration > Date.now() / 1000) {
                dispatch(setAuth(authStore));
              } else {
                HandleError({ error: rej, appId: authParams.appId, navigate, dispatch });
                g.sendEvent(GAEvents.authFailure, {
                  reason: 'API error',
                  appId: authParams.appId
                });
              }
            }
          )
          .finally(() => {
            setIsReady(true);
            dispatch(setLoading(false));
          });
      } else if (authStore && gameExists(authStore.appId) && authStore.expiration > Date.now() / 1000) {
        dispatch(setAuth(authStore));
        setIsReady(true);
        dispatch(setLoading(false));
      } else {
        setIsReady(true);
        dispatch(setLoading(false));
      }
    } else {
      dispatch(setLoading(false));
      setIsReady(true);
    }
  }, [app]);

  useEffect(() => {
    dispatch(setLoading(true));
    if (!state.auth && authStore && gameExists(authStore.appId) && authStore.expiration > Date.now() / 1000) {
      dispatch(setAuth(authStore));
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
    }
  }, [authStore?.appId]);

  const path = document.location.pathname;
  const currentApp = getAppFromPath(path);

  if (currentApp?.storeUrl?.length) {
    window.location.replace(currentApp.storeUrl);
    return <></>;
  }

  return (
    <I18nProvider i18n={i18n}>
      <SidebarMenuProvider>
        <div id='zynga-store' className={state.loading ? 'is-loading' : ''}>
          <Header />
          {isReady ? (
            <div id='page-wrap' ref={wrapRef}>
              <WrapContext.Provider value={scrollToTop}>
                <Suspense fallback={<Spinner show={true} />}>
                  <Spinner show={state.loading} />
                  <Routes>
                    <Route path='/:gameName/error' element={<LandingWrapper />} />
                    <Route
                      path='/*'
                      element={isCurrentAppAuthed(app!) && isSupportedDevice() ? <Store /> : <LandingWrapper />}
                    />
                  </Routes>
                </Suspense>
              </WrapContext.Provider>
            </div>
          ) : (
            <Spinner show={true} />
          )}
        </div>
      </SidebarMenuProvider>
    </I18nProvider>
  );
};

export default App;
