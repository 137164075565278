import { useState } from 'react';
import Menu from 'react-burger-menu/lib/menus/slide';
import { useLocation, useNavigate } from 'react-router';
import { t, Trans } from '@lingui/macro';

import { getAppById, getAppFromPath, getAuthedApps, getCoExistingApps, clearAuthStorage } from '../../helpers/Utils';
import { IApp, ISideBarMenuItem } from '../../helpers/Apps';
import { useAppState } from '../../store/store';

import { useSidebarMenuState } from './SideBarMenuContext';
// import LanguageSwitcher from './LanguageSwitcher';

import backArrow from '../../images/back-arrow.svg';
import { clearAppState } from '../../store/actions';
import { Track, UserFunnelActions } from '../../helpers/Track';
import AuthStorage from '../../helpers/AuthStorage';
import CONFIG from '../../helpers/Config';

const SIDEBAR_WIDTH = '360px';
const gameNeutralI18nPrefix = 'game_neutral';

const gameNeutralMenuItems = [
  {
    label: 'menu_item_faq',
    isExpandable: true,
    contents: [
      {
        label: 'what_is_zynga_store',
        text: 'what_is_zynga_store_ans'
      },
      {
        label: 'why_purchase_from_store',
        text: 'why_purchase_from_store_ans'
      },
      {
        label: 'which_payments_method_support',
        text: 'which_payments_method_support_ans'
      },
      {
        label: 'how_long_to_show_purchase',
        text: 'how_long_to_show_purchase_ans'
      }
    ]
  },
  {
    label: 'menu_item_customer_support',
    link: 'https://www.zynga.com/support/'
  },
  {
    label: 'menu_item_terms_of_service',
    link: 'https://www.zynga.com/legal/terms-of-service'
  },
  {
    label: 'menu_item_privacy_policy',
    link: 'https://www.take2games.com/privacy/'
  }
];

const SideBar = (props: { pageWrapId: string; outerContainerId: string }) => {
  const { isMenuOpen, stateChangeHandler } = useSidebarMenuState();

  const { state, dispatch } = useAppState();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const path = pathname.split('/')[1];
  const path2 = pathname.split('/')[2];
  const { toggleMenu } = useSidebarMenuState();

  const { auth } = state;

  let app: IApp | undefined;
  if (auth) app = getAppById(auth.appId);
  else {
    app = getAppFromPath(path) || getAppFromPath(path2);
  }
  const [selectedMenuItem, setselectedMenuItem] = useState<ISideBarMenuItem | null>();

  const OnMenuItemSelected = (item) => {
    if (item.isExpandable) setselectedMenuItem(item);
  };

  const authedApps = getAuthedApps();

  const logout = () => {
    toggleMenu();
    if (getAuthedApps().length > 0) {
      getAuthedApps().forEach((appId) => {
        const tempAuth = AuthStorage.get(appId);
        Track.init(tempAuth);
        Track.count(UserFunnelActions.log_out);
        Track.clear();
      });
    }
    clearAuthStorage();
    const currentApp = app;
    setTimeout(() => {
      if (currentApp) {
        dispatch(clearAppState(currentApp.appId));
        navigate(`/${currentApp.pathname}`);
      } else {
        navigate(`/`);
      }
    }, 300);
  };

  const navigateToApp = (app: IApp) => {
    toggleMenu();
    if (authedApps.includes(app.appId)) {
      navigate(`/${app.pathname}/catalog`);
    } else {
      navigate(`/${app.pathname}`);
    }
  };

  const i18nPrefix = app ? app.i18nPrefix : gameNeutralI18nPrefix;
  const sidebarMenuItems = app ? app.sidebarMenuItems : gameNeutralMenuItems;

  const handleMenuClose = () => {
    setTimeout(() => setselectedMenuItem(null), 300);
    toggleMenu();
  };

  const landingPageAndSidebarApps = getCoExistingApps() || [];
  return (
    <Menu
      {...props}
      width={SIDEBAR_WIDTH}
      right
      isOpen={isMenuOpen}
      onStateChange={(state) => stateChangeHandler(state)}
      onClose={() => handleMenuClose()}
    >
      <div className='sidebar-container'>
        <div className='selected-option'>
          {selectedMenuItem ? (
            <>
              <img src={backArrow} alt='back' onClick={() => setselectedMenuItem(null)} style={{ marginRight: 10 }} />
              {t({ id: selectedMenuItem?.label })}
            </>
          ) : (
            <Trans>menu</Trans>
          )}
        </div>
        <div className='h-full'>
          <div className='faq-container'>
            <>
              {selectedMenuItem ? (
                <div>
                  {selectedMenuItem?.contents?.map((item) => (
                    <div className='selected-menu-container' key={item.label}>
                      <div className='selected-menu-label'>{t({ id: item.label })}</div>
                      <div className='selected-menu-text'>{t({ id: `${i18nPrefix}_${item.text}` })}</div>
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  {landingPageAndSidebarApps.length > 1 ? (
                    <>
                      {landingPageAndSidebarApps.map((app) => (
                        <div
                          className={`menu-app-wrapper ${app.className}`}
                          key={app.appId}
                          onClick={() => navigateToApp(app)}
                        >
                          <div className='app-icon-wrapper' />
                          <div className='app-name'>{app.displayName}</div>
                        </div>
                      ))}
                      <div className='menu-app-wrapper' key='coming-soon'>
                        <div className='app-icon-wrapper' />
                        {/* TODO: i18n */}
                        <div className='app-name'>More Coming Soon !</div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  {sidebarMenuItems?.map((item) => (
                    <div key={item.label}>
                      {item.isExpandable ? (
                        <div className='menu-label' onClick={() => OnMenuItemSelected(item)}>
                          {t({ id: item.label })}
                        </div>
                      ) : (
                        <a className='menu-label' href={item.link} target={'_blank'} rel='noopener noreferrer'>
                          {t({ id: item.label })}
                        </a>
                      )}
                    </div>
                  ))}
                  {getAuthedApps().length > 0 && (
                    <div>
                      <button className='menu-label' onClick={logout}>
                        Log Out
                      </button>
                    </div>
                  )}
                </>
              )}
            </>
          </div>
          {/* <LanguageSwitcher /> */}
          <div className='sidebar-footer'>
            <Trans id='sidebar_footer'>
              ©Zynga, Inc {CONFIG.currentYear}
              <br />
              1200 Park Place, San Mateo, CA 94403
            </Trans>
          </div>
        </div>
      </div>
    </Menu>
  );
};

export default SideBar;
