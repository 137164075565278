import React from 'react';
import { t } from '@lingui/macro';

import SideBar from './SideBar';

import zyngaLogo from '../../images/icons/zstore-logo.svg';
import Banner from './Banner';

const Header = () => {
  return (
    <>
      <Banner />
      <div className='header'>
        <div className='max-w-screen-xl mx-auto relative w-full px-3'>
          <SideBar pageWrapId={'page-wrap'} outerContainerId={'header'} />
          <div className='flex items-center justify-start flex-1 h-full'>
            <div className='zynga-logo-wrapper'>
              <img src={zyngaLogo} alt={t`zynga_logo`} className='zynga-logo' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
