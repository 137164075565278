import { useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';

import { Button } from './common/Buttons';
import { DownloadButton, DownloadButtonTypes } from './common/DownloadButtons';
import { hasDeeplinkIssue, isAmazonDevice, isAndroid, isIOS } from '../helpers/device';
import { getCookieByName, isCurrentAppPoker, setBodyClass, setCookie } from '../helpers/Utils';
import { IApp } from '../helpers/Apps';
import { useAppState } from '../store/store';
import DialogBox from './common/DialogBox';

import BrowserHint from './BrowserHint';
import CONFIG from '../helpers/Config';

interface HomeProps {
  message: string | React.ReactNode;
  app?: IApp;
  showDownloadButton?: boolean;
}

const viewedAppLinkCookieName = 'viewed_app_link_msg';
const viewedAppLinkCookieValue = '1';

const Home = ({ message, app, showDownloadButton = false }: HomeProps) => {
  const { state } = useAppState();
  const navigate = useNavigate();
  const [showGetHelp, setShowGetHelp] = useState(false);

  const appClassName = (app && app.className) || 'game-neutral';
  const gameName = app && app.displayName;
  const showGetHelpButton = hasDeeplinkIssue() && showDownloadButton;
  const seenApplink = getCookieByName(viewedAppLinkCookieName) === viewedAppLinkCookieValue;

  useEffect(() => {
    if (!seenApplink && showGetHelpButton) {
      setShowGetHelp(true);
      setCookie(viewedAppLinkCookieName, viewedAppLinkCookieValue);
    }
  }, [seenApplink, showGetHelpButton]);

  if (app) {
    setBodyClass('game-', app && [app.pathname, app.appId, app.className]);
  } else {
    setBodyClass('game-');
  }

  // change poker background for home page
  if (isCurrentAppPoker(app)) {
    const rootElement = document.getElementById('root');
    rootElement?.classList.add('home-page-background');
  }

  return (
    <>
      {state?.error ? (
        <DialogBox message={t({ id: state?.error?.message })} />
      ) : (
        <div className={`home ${appClassName} ${showGetHelp && 'show-browser-hint'}`}>
          <div className='home-outer-wrapper'>
            <div className='home-wrapper'>
              {app && showGetHelp && (
                <BrowserHint
                  onClose={() => {
                    navigate(app.pathname);
                    setShowGetHelp(false);
                  }}
                ></BrowserHint>
              )}
              <div className={`message-container ${showGetHelp ? 'invisible' : ''}`}>
                <div className='message-outer'>
                  <div className='message-inner'>
                    <div className='message'>{message}</div>
                    {showGetHelpButton && (
                      <div className='mt-3 w-full'>
                        <div className='mb-1 message'>
                          <Trans id='browser_hint_installed'>Already installed?</Trans>
                        </div>
                        <Button
                          size='sm'
                          onClick={() => {
                            setShowGetHelp(true);
                          }}
                          className='item-button primary'
                          btnTextClassName='browser-hint-btn-text'
                        >
                          <Trans id='browser_hint_help'>Get Help</Trans>
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {showDownloadButton && isIOS() && app && (
                <DownloadButton type={DownloadButtonTypes.APPLE} app={app} className='h-15 mt-8' />
              )}
              {showDownloadButton &&
                isAndroid() &&
                app &&
                (isAmazonDevice() && app.amazonStoreLink ? (
                  <DownloadButton type={DownloadButtonTypes.AMAZON} app={app} className='h-15 mt-8' />
                ) : (
                  <DownloadButton type={DownloadButtonTypes.GOOGLE_PLAY} app={app} className='h-15 mt-8' />
                ))}
              {app ? (
                <div className='footer'>
                  {/* prettier-ignore */}
                  <Trans id='footer'>The official store of {gameName}<span className='hidden exclaimation'>!</span><br />Only at store.zynga.com</Trans>
                </div>
              ) : (
                <div className='footer'>
                  {/* prettier-ignore */}
                  <Trans id='generic_page_footer'>The official store of Zynga games.<br />Only at store.zynga.com <br/>©Zynga, Inc {CONFIG.currentYear}</Trans>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
