import { v4 as uuidv4 } from 'uuid';
import { hexToDec, decToHex } from 'hex2dec';
import { UAParser } from 'ua-parser-js';

import CONFIG, { UniversalLinkType } from './Config';
import { IDevice } from '../types';
import { NavigateFunction } from 'react-router-dom';
import { IApiError } from './Ajax';
import LocalStorage from './LocalStorage';
import { clearAuth, IAction, setError } from '../store/actions';
import { IApp } from './Apps';
import AuthStorage from './AuthStorage';
import { isAndroid, isIOS } from './device';

const userAgentParse = new UAParser();

// The signed bit value
const byteSignedBit: number = 128;
const byteMask: number = (1 << 8) - 1; // 0b01111111
const negBit: number = 1 << 7; // 0b10000000
export const pokerAppId = '1';
export const empirePuzzleAppId = '5005022';
export const hirAppId = '5002366';
export const wozSlotsAppId = '5003043';
export const wwfAppId = '5003741';
export const mergeDragonsAppId = '5004763';
export const dragonCityAppId = '5006138';

export const userAgentData = userAgentParse.getResult();

/**
 * Checks if a hex string is a negative number in 2s compliment format
 * @param hexString The number in hex representation
 * @returns True if the hex encoded number is negative in 2s compliment encoding
 */
function hexIsNegative(hexString: string): boolean {
  // Grab the first byte from the hex string
  const mostSignificantByte: number = parseInt(hexString.substr(0, 2), 16);
  // Check if most significant bit is set
  return (byteSignedBit & mostSignificantByte) > 0;
}

/**
 * Flipps all the bits in a hex string.  Used for 2s compliment conversion
 * @param hexString The hex string to flip all the bits on
 * @returns The hex string with all the bits flipped
 */
function hexBitFlip(hexString: string) {
  const newChars: Array<string> = [];
  let byteHex: string = '';
  let byte: number;
  for (let n = 0; n < hexString.length; n += 2) {
    // Get the nth byte
    byte = parseInt(hexString.substr(n, 2), 16);
    // Flip all the bits for 2s compliment
    byte = ~byte;
    // Logical AND to strip the first bytes of the multi-byte int
    // Otherwise it'll be 111111111111111111111111xxxxxxxx and we only want xxxxxxxx
    byte &= byteMask;

    byteHex = byte.toString(16).padStart(2, '0');
    newChars.push(byteHex);
  }
  return newChars.join('');
}

/**
 * Converts an hex number in 2s compliment signed format to an unsigned hx representation
 * @param hexString The hex number to convert
 * @returns The same number in unsigned hex format (will be the same if positive)
 */
function signedHexToUnsighedHex(hexString) {
  if (!hexIsNegative(hexString)) {
    // Not negative, short circuit
    return hexString;
  }
  const flippedHex: string = hexBitFlip(hexString);
  let firstByte = parseInt(flippedHex.substr(0, 2), 16);
  // Clear the MSB to force a positive interpretation
  // Only done on the first hex char pair as that's the MSB for big-endian representation
  firstByte &= ~negBit;
  return firstByte.toString(16).padStart(2, '0') + flippedHex.substr(2, 14);
}

/**
 * Converts an unsigned hex number to a 2s compliment signed hex format.
 * @param hexString The number, hex encoded (unsigned)
 * @param toNegative Should the number be converted to a negatibve or not
 * @returns The same number but in a hex representation of a signed integer (2s compliment encoding)
 */
/* lcarl:  Uncomment if ever needed
function unsignedHexToSignedHex(hexString: string, toNegative: boolean) {
  const flippedHex = hexBitFlip(hexString);
  let firstByte = parseInt(flippedHex.substr(0, 2), 16);
  if (toNegative !== true) {
    return hexString;
  } else {
    // Set the MSB to force a negative interpretation/representation
    // Only done on the first hex char pair as that's the MSB for big-endian representation
    firstByte |= negBit;
  }
  return firstByte.toString(16).padStart(2, "0") + flippedHex.substr(2, 14);
}
*/

/**
 *
 * @param hexString The numnber in hex string format
 * @param lsbValue The least significant bit value (0 or 1)
 * @returns A new number with the least significant bit set to lsbValue in hex string format
 */
function setHexLeastSignificantBit(hexString: string, lsbValue: 0 | 1): string {
  const hexLength = hexString.length;
  let leastSignificantByte: number = parseInt(hexString.substr(hexLength - 2, 2), 16);
  if (lsbValue === 1) {
    // Set lsb
    leastSignificantByte |= 1;
  } else {
    // Clear lsb
    leastSignificantByte &= ~1;
  }
  return hexString.substr(0, hexLength - 2) + leastSignificantByte.toString(16);
}

/**
 * Returns client and server appLoadIds as a strings
 */
export const generateAppLoadId = (): string => {
  const uuidHex = uuidv4().split('-').join(''); // Strip all - chars
  // const uuidHex = '384000008cf011bdb23e10b96e4ef00d';
  let lsbHex = uuidHex.substring(16, 32); // Get 64 least significant bits characters (16 hex chars)
  const isNegativeRepresentation = hexIsNegative(lsbHex);
  const newHex64 = signedHexToUnsighedHex(lsbHex);
  return (isNegativeRepresentation ? '-' : '') + hexToDec(`0x${setHexLeastSignificantBit(newHex64, 1)}`);
};

export const generateServerAppLoadId = (clientAppLoadId: string): string => {
  const isNegative = clientAppLoadId[0] === '-';
  const unsignedAppLoadId = clientAppLoadId.substr(isNegative ? 1 : 0);
  const unsignedHex = decToHex(unsignedAppLoadId) as string;
  // Note ${unsignedHex} will be prepended with 0x so we don't need to add this here
  return (isNegative ? '-' : '') + hexToDec(setHexLeastSignificantBit(unsignedHex, 0));
};
/**
 * UUID V4
 */
export const uuid = (): string => {
  return uuidv4();
};
/**
 * Returns the best match for Stripe locale
 */
export const getStripeLocale = (locale: string): string => {
  const code = cleanLocale(locale);
  const code2 = code.substring(0, 2);
  const fullCode = CONFIG.stripeLocaleCodes.find((loc) => cleanLocale(loc) === code);
  const shortCode = CONFIG.stripeLocaleCodes.find((loc) => cleanLocale(loc) === code2);
  return fullCode || shortCode || 'en';
};

const cleanLocale = (code: string): string => {
  return code.replace(/[^a-zA-Z]/g, '').toLowerCase();
};

type IUniversalLinkProps = {
  type: UniversalLinkType;
  appId: string | number;
  appLoadId: string;
};
/**
 * Returns the universal link into the app
 */
export const getUniversalLink = ({ appId, appLoadId, type }: IUniversalLinkProps): string | undefined => {
  if (type === UniversalLinkType.open) {
    const duuid = getDeviceId();
    return (
      CONFIG.universalLink.host +
      '/' +
      CONFIG.universalLink.open +
      '/' +
      appId +
      '/?zt=' +
      encodeURIComponent(appLoadId) +
      '&duuid=' +
      encodeURIComponent(duuid)
    );
  } else if (type === UniversalLinkType.close) {
    return (
      CONFIG.universalLink.host +
      '/' +
      CONFIG.universalLink.close +
      '/' +
      appId +
      '/?zt=' +
      encodeURIComponent(appLoadId)
    );
  }
  console.error('unsupported link type: ' + type);
  return undefined;
};
/**
 * Returns a link to the store to begin the auth process
 */
export const getStoreLink = ({ appId, appLoadId }: { appId: number | string; appLoadId: string }): string => {
  return (
    document.location.protocol + '//' + document.location.host + '/' + appId + '/?zt=' + encodeURIComponent(appLoadId)
  );
};
/**
 * Get the app from gameName
 */
export const getAppByName = (pathname: string | undefined) => {
  if (pathname) {
    return CONFIG.apps.find((x) => x.pathname === pathname);
  }
};
/**
 * Get the app from appId
 */
export const getAppById = (appId: string | number | undefined | null) => {
  if (appId) {
    return CONFIG.apps.find((x) => x.appId === appId.toString());
  }
};
/**
 * Get app from either appId or gameName or path
 */
export const getAppFromPath = (pathname: string | number | undefined) => {
  if (typeof pathname === 'string' && pathname.indexOf('/') > -1) {
    const pathArray = pathname.split('/');
    return pathArray.reduce((prev: IApp | undefined, curr) => {
      return prev || getAppById(curr) || getAppByName(curr);
    }, undefined);
  }
  return getAppById(pathname) || getAppByName(pathname as string);
};
/**
 * Returns the device uuid from storage if found, otherwise creates a new one
 */

export const getDeviceId = (): string => {
  const newDuuid = uuid();

  const currentDuuid: { value: string; expiration: number } | null = LocalStorage.getItem(CONFIG.duuid.name);
  if (
    !currentDuuid ||
    // TODO: set relative to token expiration so we know the duuid will be valid for the token duration?
    (currentDuuid && currentDuuid.expiration && currentDuuid.expiration - Math.floor(Date.now() / 1000) < 0)
  ) {
    // duuid doesn't exist or is expired, generate duuid and store it
    LocalStorage.setItem(CONFIG.duuid.name, {
      value: newDuuid,
      expiration: CONFIG.duuid.duration + Math.floor(Date.now() / 1000)
    });
    return newDuuid;
  }
  return currentDuuid.value;
};

/**
 * generates a new duuid and stores it in the browser
 */
export const setFreshDuuid = () => {
  LocalStorage.setItem(CONFIG.duuid.name, {
    value: uuid(),
    expiration: CONFIG.duuid.duration + Math.floor(Date.now() / 1000)
  });
};
/**
 * Returns the form factor of a device
 */
const getFormFactor = (): string => {
  switch (userAgentData.device.model) {
    case 'iPhone':
      return 'phone';
    case 'iPad':
      return 'tablet';
    default:
      return 'unknown';
  }
};
/**
 * Returns the device data for purchases calls
 */
export const getDeviceData = (): IDevice => {
  return {
    device_id: getDeviceId(),
    form_factor: getFormFactor(),
    game_version: CONFIG.appVersion,
    manufacturer: userAgentData.device.vendor || '',
    model: userAgentData.device.model || '',
    os: userAgentData.os.name || '',
    os_version: userAgentData.os.version || '',
    sdk_version: '',
    store: CONFIG.zyngaStore
  };
};
/**
 * Sets the body class based on prefix and value.  If no value is passed clears that class type.
 */
export const setBodyClass = (prefix: string, value?: string | string[]) => {
  const classlist = document.body.classList;
  const cleanClassName = (name: string) => {
    return name
      .toString()
      .replace(/[^a-zA-Z0-9_-]/g, '')
      .toLowerCase();
  };
  const currentClass = classlist
    .toString()
    .split(' ')
    .filter((name) => name.startsWith(prefix));

  if (currentClass.length >= 1) {
    classlist.remove(...currentClass);
  }
  if (!value) {
    return; // if no value clear current prefix classes
  }
  const newClass =
    typeof value === 'string' ? [prefix + cleanClassName(value)] : value.map((val) => prefix + cleanClassName(val));
  classlist.add(...newClass);
};
type IHandleErrorProps = {
  error: IApiError;
  appId: string | number;
  navigate: NavigateFunction;
  dispatch: React.Dispatch<IAction>;
};

export const HandleError = ({ error, appId, navigate, dispatch }: IHandleErrorProps) => {
  let errorMessage;
  let isInlineError = false;
  if (error?.category) {
    switch (error?.category) {
      case 'pbr.auth.MalformedAuth':
      case 'pbr.auth.Expired':
      case 'pbr.auth.NetworkTokenInvalid': {
        errorMessage = CONFIG.errors.authInvalid;
        break;
      }
      case 1002: {
        errorMessage = CONFIG.errors.not_authorize;
        break;
      }
      case 1000:
      case 1021:
      case 1117:
      case 1120:
      case 'generic_error': {
        errorMessage = CONFIG.errors.generic_error;
        isInlineError = true;
        break;
      }
      default:
        errorMessage = error?.message || CONFIG.errors.generic_error;
        isInlineError = true;
    }
  }
  const app = getAppById(appId);
  if (app && errorMessage) {
    if (isInlineError) {
      dispatch(setError({ message: errorMessage }));
    } else {
      dispatch(clearAuth(app.appId));
      dispatch(setError({ message: errorMessage }));
      navigate(`/${app.pathname}/`);
    }
  }
};

export const isTokenExpired = (unixTimestamp: number, expirationDuration: number) =>
  unixTimestamp * 1000 - Date.now() < expirationDuration;

export const devLogger = {
  log: (...args) => {
    if (process.env.REACT_APP_ENV !== 'production') {
      console.log(...args);
    }
  },
  table: (data: any[] = []) => {
    if (process.env.REACT_APP_ENV !== 'production' && data.length) {
      console.table(data);
    }
  }
};
export const getCookieByName = (name: string) => {
  const cookie = document.cookie.split(';').find((cookie) => cookie && cookie.trim().startsWith(name + '='));
  if (cookie) {
    const val = cookie.trim().split(';')[0].split('=')[1];
    return val;
  }
};
export const setCookie = (name: string, value: string) => {
  document.cookie = `${name}=${value}; max-age=${30 * 24 * 60 * 60};path=/;`;
};
export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

export const isEmailValid = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};
export const isCurrentAppPoker = (app?: IApp) => app?.appId === pokerAppId || app?.parentAppId === pokerAppId;

export const currentAppName = (app?: IApp) =>
  app?.appId === pokerAppId || app?.parentAppId === pokerAppId
    ? CONFIG.appsName.poker
    : app?.appId === empirePuzzleAppId || app?.parentAppId === empirePuzzleAppId
    ? CONFIG.appsName.empire_and_puzzle
    : CONFIG.appsName.hit_it_rich;

export const getAuthedApps = () =>
  CONFIG.apps
    .map((app) => app.appId)
    .filter((appId) => AuthStorage.get(appId) && AuthStorage.get(appId).expiration > Date.now() / 1000);

export const clearAuthStorage = () => {
  getAuthedApps().forEach((appId) => {
    AuthStorage.delete(appId);
  });
};
export const getCoExistingApps = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return CONFIG.apps.filter((app) => !app.parentAppId && !app.hide);
  } else {
    return CONFIG.apps.filter((app) => app.parentAppId && !app.hide);
  }
};

export const isCurrentAppAuthed = (app: IApp) => app && getAuthedApps().includes(app.appId);

/*
 * Returns human readable time in days:hours:minutes:seconds format
 */
export const getTimeFromUnix = (endTime: number) => {
  let diffTime = Math.abs(endTime * 1000 - new Date().getTime());

  let days = diffTime / (24 * 60 * 60 * 1000);
  let hours = (days % 1) * 24;
  let minutes = (hours % 1) * 60;
  let secs = (minutes % 1) * 60;
  [days, hours, minutes, secs] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(secs)];

  // console.log(days + 'd', hours + 'h', minutes + 'm', secs + 's');
  return days && days > 6
    ? `${Math.floor(days / 7)}w ${Math.floor(days % 7)}d`
    : days
    ? `${days}d ${hours}h`
    : hours
    ? `${hours}h ${minutes}m`
    : minutes
    ? `${minutes}m ${secs}s`
    : `${secs}s`;
};

/*
 * Returns marketing msg with all white spaces removed
 */
export const getMarketingMsgClass = (msg: string) =>
  CONFIG.marketingMessages.find((msgText) => msg.replace(/[^a-zA-Z]/g, '').toLowerCase() === msgText);

/*
 * Returns if Apple Pay can be shown for the browser/device
 */

export const canHaveApplePay = (): boolean => {
  return !!isIOS() && !!(<any>window).ApplePaySession;
};

/*
 * Returns if Google Pay can be shown for the browser/device
 * Apple Pay will override Google Pay when it is available
 */

export const canHaveGooglePay = (): boolean => {
  return (isAndroid() || userAgentData.browser.name === 'Chrome') && !canHaveApplePay();
};

/**
 * Returns if any Stripe vendor might be shown for the browser/device.
 */
export const canHaveStripeVendor = () => {
  return canHaveApplePay() || canHaveGooglePay();
};
