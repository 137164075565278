export const NumberFormat = (number: number | string) => {
  if (number === undefined || number === null) {
    return '';
  }
  if (typeof number === 'string') {
    number = +number;
  }
  // TODO: i18n
  const formatter = new Intl.NumberFormat('en-US');
  return formatter.format(number);
};

export const TitleCaseFormat = (str: string) => {
  return str.charAt(0).toUpperCase() + str.substring(1);
};
