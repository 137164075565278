import { ICatalogItem, IItem, IAuth, IPurchase, IPlayer } from '../types';

import {
  IAction,
  CLEAR_AUTH,
  CLEAR_CART,
  SET_AUTH,
  SET_CART,
  SET_ITEMS,
  SET_LOADING,
  SET_STRIPE_KEY,
  SET_PLAYER,
  SET_ERROR,
  CLEAR_ERROR,
  CLEAN_APP_STATE,
  UPDATE_AUTH
} from './actions';
import AuthStorage from '../helpers/AuthStorage';

import { Track } from '../helpers/Track';

export type IError = {
  message?: string;
};

export type IState = {
  currentAppId: string | null;
  loading: boolean;
  auth: IAuth | null;
  isAuthed: boolean;
  items: IItem[] | null;
  stripeKey: string | null;
  catalog: ICatalogItem[] | null;
  purchase: IPurchase | null;
  cart: IItem | null;
  player: IPlayer | null;
  error: IError | null;
};
export const initialState: IState = {
  currentAppId: null,
  loading: true,
  auth: null,
  isAuthed: false,
  items: null,
  stripeKey: null,
  catalog: null,
  purchase: null,
  cart: null,
  player: null,
  error: null
};

const cleanState = {
  auth: null,
  isAuthed: true,
  items: null,
  stripeKey: null,
  catalog: null,
  purchase: null,
  cart: null,
  player: null
};

export const reducer = (state: IState, action: IAction): IState => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      return { ...state, loading: payload };

    case SET_STRIPE_KEY:
      return { ...state, stripeKey: payload };

    case SET_AUTH:
      Track.init({ ...payload });
      AuthStorage.set(payload.appId, payload);
      return { ...state, auth: payload, isAuthed: true, currentAppId: payload.appId };

    case UPDATE_AUTH:
      AuthStorage.update(payload.appId, payload.auth);
      return { ...state, auth: payload.auth };

    case CLEAN_APP_STATE:
      Track.clear();
      return { ...state, ...cleanState, currentAppId: payload };

    case CLEAR_AUTH:
      Track.clear();
      AuthStorage.delete(payload);
      return { ...initialState };

    case SET_ITEMS:
      return { ...state, items: payload };

    case SET_CART:
      return { ...state, cart: payload.cart, purchase: payload.purchase };

    case CLEAR_CART:
      return { ...state, cart: null, purchase: null };

    case SET_PLAYER:
      return { ...state, player: payload };

    case SET_ERROR:
      return { ...state, error: payload.error };

    case CLEAR_ERROR:
      return { ...state, error: null };

    default:
      return state;
  }
};
