import AppsConfig from './Apps.config';

export type IMockItem = {
  display_price: string;
  name: string;
  icon_url: string;
  tags: string[];
};
export type IContentItem = {
  label: string;
  text: string;
};
export type ISideBarMenuItem = {
  label: string;
  text: string;
  isExpandable?: boolean;
  link: string;
  contents?: IContentItem[];
};
export type IApp = {
  appId: string;
  pathname: string;
  className: string;
  displayName: string;
  items: IMockItem[];
  sidebarMenuItems?: ISideBarMenuItem[];
  appleStoreLink: string;
  googlePlayLink: string;
  i18nPrefix: string;
  amazonStoreLink?: string;
  icon_url?: string;
  parentAppId?: string;
  hide?: boolean;
  defaultDisplayBonus?: string;
  storeUrl?: string;
};
type IDevApp = {
  parentAppId: string;
  appId: string;
  hide?: boolean;
};
type IAppData = {
  prodApps: IApp[];
  devApps: IDevApp[];
};

const { prodApps, devApps } = AppsConfig as IAppData;

const addApps: IApp[] = devApps
  .filter((devApp) => prodApps.find((app) => app.appId === devApp.parentAppId))
  .map((devApp) => {
    const baseApp = prodApps.find((app) => app.appId === devApp.parentAppId) as IApp;
    return {
      ...baseApp,
      ...devApp,
      pathname: baseApp.pathname + '-' + devApp.appId
    };
  });

const apps = [...prodApps, ...addApps];

export default apps;
