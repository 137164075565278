import { Trans } from '@lingui/macro';

import { Button } from './common/Buttons';

import { isFirefox, isSamsung, isSilk } from '../helpers/device';
import ffIcon from '../images/icons/firefox-icon.png';
import samsungIcon from '../images/icons/samsung-icon.png';
import silkIcon from '../images/icons/silk-icon.png';

const BrowserHint = ({ onClose }: { onClose: Function }) => {
  const icon = isFirefox() ? ffIcon : isSamsung() ? samsungIcon : silkIcon;
  const title = isFirefox() ? (
    <Trans id='browser_hint_title_firefox'>Firefox Users</Trans>
  ) : isSamsung() ? (
    <Trans id='browser_hint_title_samsung'>Samsung Browser Users</Trans>
  ) : (
    <Trans id='browser_hint_title_silk'>Amazon Silk Users</Trans>
  );
  return (
    <>
      <div className='browser-hint-bkg'></div>
      <div className='browser-hint-wrapper'>
        <div className='message-container browser-hint'>
          <div className='message-inner'>
            <img className='browser-icon' src={icon} alt='' />
            <h3>{title}</h3>
            <p>
              {isSilk() ? (
                <Trans id='browser_hint_body_silk'>
                  "Pop-ups and redirects" must be enabled for the Zynga Store Store
                </Trans>
              ) : (
                <Trans id='browser_hint_body'>"Open links in apps" must be enabled for the Zynga Store</Trans>
              )}
            </p>
            <ol className='text-left list-decimal'>
              <li>
                {isSilk() ? (
                  <Trans id='browser_hint_line_1_silk'>Tap the Settings menu.</Trans>
                ) : (
                  <Trans id='browser_hint_line_1'>Tap the menu button.</Trans>
                )}
              </li>
              <li>
                {isSilk() ? (
                  <Trans id='browser_hint_line_2_silk'>Tap Apps &amp; Games.</Trans>
                ) : (
                  <Trans id='browser_hint_line_2'>Tap Settings.</Trans>
                )}
              </li>
              <li>
                {isFirefox() ? (
                  <Trans id='browser_hint_line_3_ff'>Scroll down to the Advanced section.</Trans>
                ) : isSamsung() ? (
                  <Trans id='browser_hint_line_3_s'>Tap Useful Features.</Trans>
                ) : (
                  <Trans id='browser_hint_line_3_silk'>
                    Choose Amazon Application Settings &gt; Silk Browser &gt; Advanced Settings &gt; Site Settings.
                  </Trans>
                )}
              </li>
              <li>
                {isSilk() ? (
                  <Trans id='browser_hint_line_4_silk'>Enable pop-ups and redirects.</Trans>
                ) : (
                  <Trans id='browser_hint_line_4'>Turn "Open links in apps" to ON.</Trans>
                )}
              </li>
            </ol>
            <Button
              size='sm'
              onClick={onClose}
              className='item-button primary'
              btnTextClassName='browser-hint-btn-text'
            >
              <Trans id='browser_hint_done'>Done</Trans>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrowserHint;
