import React, { useReducer, useContext } from 'react';
import { IAction } from './actions';
import { initialState, IState, reducer } from './reducers';

export const AppContext = React.createContext<{ state: IState; dispatch: React.Dispatch<IAction> }>({
  state: initialState,
  dispatch: () => null
});

export const AppStateProvider: React.FC = ({ children }): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    state,
    dispatch
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppState = () => {
  return useContext(AppContext);
};
