import React from 'react';
import { Trans } from '@lingui/macro';

import { clearError } from '../../store/actions';
import { useAppState } from '../../store/store';
import { useNavigate } from 'react-router';

type DialogBoxProps = {
  message: React.ReactNode;
};

const DialogBox = (props: DialogBoxProps) => {
  const { state, dispatch } = useAppState();
  const navigate = useNavigate();

  const onClose = () => {
    state.error ? dispatch(clearError()) : navigate('/');
  };
  return (
    <>
      <div className='h-screen z-10 fixed inset-0' style={{ background: 'rgba(35,35,35,.8' }} onClick={onClose} />
      <div className='h-screen flex items-center px-4 justify-center fixed top-0 bottom-0 left-0 right-0 z-10 pointer-events-none'>
        <div className='bg-white dialog-wrapper py-4 px-6 font-helvetica z-10'>
          <div className=' message text-black'>{props.message}</div>
          <div className='primary-blue flex justify-end mt-6'>
            <button className='text-primary-blue' onClick={onClose}>
              <Trans>Close</Trans>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DialogBox;
