import { useState, useContext, createContext } from 'react';

type ISidebarState = {
  isOpen: boolean;
};

export const SidebarMenuContext = createContext<{
  isMenuOpen: boolean;
  toggleMenu: () => void;
  stateChangeHandler: (s: ISidebarState) => void;
  onClose: (fnc: Function) => void;
}>({
  isMenuOpen: false,
  toggleMenu: () => {},
  stateChangeHandler: () => {},
  onClose: () => {}
});

export const SidebarMenuProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false);

  return (
    <SidebarMenuContext.Provider
      value={{
        isMenuOpen: menuOpenState,
        toggleMenu: () => setMenuOpenState(!menuOpenState),
        stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
        onClose: (fnc) => fnc()
      }}
    >
      {props.children}
    </SidebarMenuContext.Provider>
  );
};

export const useSidebarMenuState = () => {
  return useContext(SidebarMenuContext);
};
