import LocalStorage from './LocalStorage';
import { IAuth } from '../types/Auth';

type ILocalStore = IAuth & { cart?: string };
/**
 * NOTE:
 * Initially I thought about breaking this down by app Id, but that may not be a good idea
 * since, in theory, we need to look up appId from the authstorage.  Maybe create a session store
 * that just stores the appId so we know which tab holds which appId?
 */
const makeAuthKey = (appId: string | number) => {
  return `auth-${appId}`;
};

const AuthStorage = {
  get: (appId: string | number) => {
    return LocalStorage.getItem(makeAuthKey(appId)) as ILocalStore;
  },
  set: (appId: string | number, data: ILocalStore) => {
    return LocalStorage.setItem(makeAuthKey(appId), data);
  },
  delete: (appId: string | number) => {
    return LocalStorage.deleteItem(makeAuthKey(appId));
  },
  update: (appId: string | number, data: Partial<ILocalStore>) => {
    const key = makeAuthKey(appId);
    const currentValue = LocalStorage.getItem(key);
    if (currentValue === null) {
      return LocalStorage.setItem(key, data);
    }
    return LocalStorage.setItem(key, { ...(currentValue as ILocalStore), ...data });
  }
};
export default AuthStorage;
