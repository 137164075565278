import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, StripeElementLocale } from '@stripe/stripe-js';
import { StripeElementStyle } from '@stripe/stripe-js';

import { IApplePay, IGooglePay, IItem, IPurchase, ITransaction } from '../../types';
import { canHaveStripeVendor, getStripeLocale } from '../../helpers/Utils';

import CreditCardForm from './CreditCard/CreditCardForm';
import AppleOrGooglePay from './ApplePay/AppleOrGooglePay';
import { IStripeElementPuchaseAgreementStyle } from './CreditCard/CreditCardMetadata';

type IStripeProps = {
  stripePromise: Promise<Stripe | null>;
  onComplete: (props: ITransaction & { vendor: string; brand: string }) => void;
  locale: string;
  purchase: IPurchase;
  token: string;
  showEmail?: boolean;
  showZip?: boolean;
  showSavedCards?: boolean;
  handleOFCAErrors: (props: { purchase: IPurchase; cart: IItem }) => void;
  syncStoreData: (udpateBalanceOnly?: boolean) => Promise<boolean>;
  cardElementsStyle: StripeElementStyle & IStripeElementPuchaseAgreementStyle;
  showDeleteCard: (show: boolean) => void;
  showCC: boolean;
  applePay?: IApplePay | false;
  googlePay?: IGooglePay | false;
  item: IItem;
  onCartError: (err?: string) => void;
};
const StripeComponent = (props: IStripeProps) => {
  return (
    <Elements
      stripe={props.stripePromise}
      options={{
        locale: getStripeLocale(props.locale) as StripeElementLocale
      }}
    >
      {canHaveStripeVendor() && (
        <div className={!props.showCC && props.applePay ? 'apple-pay block text-center' : 'hidden'}>
          <AppleOrGooglePay
            purchase={props.purchase}
            token={props.token}
            onComplete={props.onComplete}
            applePay={props.applePay as IApplePay}
            googlePay={props.googlePay as IGooglePay}
            item={props.item}
            onCartError={props.onCartError}
          />
        </div>
      )}
      <div className={props.showCC ? 'block text-center' : 'hidden'}>
        <CreditCardForm {...props} />
      </div>
    </Elements>
  );
};
export default StripeComponent;
