import React from 'react';
import { NumberFormat } from '../../helpers/formatter';
import { IPunchCard } from '../../types';

type PunchCardProps = {
  punchCard: IPunchCard;
  maxPurchases?: number;
  purchasesMade: number;
  remainingPurchases: number;
  classes?: string;
};

const PunchCard = ({ punchCard, maxPurchases, purchasesMade, remainingPurchases, classes }: PunchCardProps) => {
  const { remaining_purchases, reward_quantity, reward_icon_url, complete_message, marketing_message } = punchCard;

  const purchasesDone = purchasesMade > maxPurchases! ? maxPurchases : purchasesMade;

  const showPunchCardDetails = () => remaining_purchases === 0 || purchasesMade >= maxPurchases!;

  return (
    <div className={`punch-card-wrapper ${classes}`}>
      <div className='quantity-amount-wrapper'>
        {[...Array(purchasesDone)].map((n, i) => (
          <img
            className='filled-icon'
            src={'https://dmcm4hleehsxo.cloudfront.net/zstore/icons/stamps.png'}
            alt=''
            key={i}
          />
        ))}
        {purchasesMade < maxPurchases! &&
          [...Array(remainingPurchases)].map((n, i) => (
            <div className={`quantity`} key={i}>
              {purchasesMade + i + 1}
            </div>
          ))}
        <p className='ml-1'>=</p>
        <div className='amount'>
          {NumberFormat(reward_quantity)}
          <img src={reward_icon_url} alt='' width={26} className='reward-img'></img>
        </div>
      </div>
      <div className='message-wrapper'>{showPunchCardDetails() ? complete_message : marketing_message}</div>
    </div>
  );
};

export default PunchCard;
