let AppsConfig = {};

if (process.env.REACT_APP_ENV === 'production') {
  AppsConfig = require('./Apps.config.production.json');
} else if (process.env.REACT_APP_ENV === 'staging') {
  AppsConfig = require('./Apps.config.staging.json');
} else {
  AppsConfig = require('./Apps.config.development.json');
}

export default AppsConfig;
