import React, { Ref } from 'react';

type IButtonProps = {
  type?: 'submit' | 'reset' | 'button';
  children: React.ReactNode;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  [x: string]: any;
  btnTextClassName?: string;
};

export const Button = React.forwardRef((props: IButtonProps, ref: Ref<HTMLButtonElement>) => {
  const { type, className, children, size, btnTextClassName, ...rest } = props;
  const classSize = size ? 'btn-' + size : 'btn-md';
  return (
    <>
      {ref ? (
        <button type={type || 'button'} className={`btn ${classSize} ${className}`} {...rest} ref={ref}>
          <div className='btn-extra'></div>
          <div className='btn-inner'>{children}</div>
        </button>
      ) : (
        <button type={type || 'button'} className={`btn ${classSize} ${className}`} {...rest}>
          <div className='btn-extra'></div>
          <div className={`btn-inner ${btnTextClassName}`}>{children}</div>
        </button>
      )}
    </>
  );
});
