import { i18n } from '@lingui/core';
import { en, ko } from 'make-plural/plurals';
import { detect, fromUrl, fromStorage, fromNavigator } from '@lingui/detect-locale';
import I18nStorage from './helpers/I18nStorage';
import { setBodyClass } from './helpers/Utils';

export const locales = {
  en: 'English',
  ko: 'Korean'
};
export const defaultLocale = 'en';

const locClassPrefix = 'loc-';

i18n.loadLocaleData({
  en: { plurals: en },
  ko: { plurals: ko }
});

const LOCAL_STORAGE_KEY = 'locale';

// checks that detected locale is available
const isLocaleValid = (locale: any) => `${locale}` in locales;

const getLocale = () => {
  let detectedLocale: string | null = detect(
    fromUrl('lang'), // for example http://localhost:3000/?lang=es
    fromStorage(LOCAL_STORAGE_KEY),
    fromNavigator(), // from system settings
    () => defaultLocale
  );

  //removing "" from lang received from localstorage
  detectedLocale = (detectedLocale && detectedLocale.replace(/"/g, '')) || defaultLocale;

  return isLocaleValid(detectedLocale) ? detectedLocale : defaultLocale;
};

/**
 * We do a dynamic import of just the catalog that we need
 */
const dynamicActivate = async (locale: any) => {
  // "@lingui/loader" to compile messages on the fly, no need to run yarn compile
  const { messages } = await import(`@lingui/loader!./locales/${locale}/messages.json`);

  i18n.load(locale, messages);
  i18n.activate(locale);
  
  setBodyClass(locClassPrefix, locale);

  I18nStorage.set(locale);
};

export const i18nConfig = {
  getLocale,
  dynamicActivate
};
