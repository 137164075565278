const Spinner = ({ show, local }: { show: boolean; local?: boolean }) => {
  return (
    <div className={`loading-spinner ${show ? '' : 'hide-spinner'} ${local ? 'local-spinner' : ''}`}>
      <div className='z-spinner'>
        <div className='seg-1'></div>
        <div className='seg-2'></div>
        <div className='seg-3'></div>
        <div className='seg-4'></div>
        <div className='seg-5'></div>
        <div className='seg-6'></div>
        <div className='seg-7'></div>
        <div className='seg-8'></div>
        <div className='seg-9'></div>
        <div className='seg-10'></div>
        <div className='seg-11'></div>
        <div className='seg-12'></div>
      </div>
    </div>
  );
};
export default Spinner;
