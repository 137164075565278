export enum Vendor {
  stripe = 'STRIPE',
  braintree = 'BRAINTREE',
  applepay = 'APPLE_PAY_STRIPE',
  googlepay = 'GOOGLE_WALLET_STRIPE'
}

export type IStripeMetadata = {
  address_required: boolean | null;
  email_required: boolean | null;
  zip_required: boolean | null;
};
export type IStripe = {
  attributes: any;
  metadata: IStripeMetadata;
  name: Vendor.stripe;
  preferred: boolean;
};
export type IBraintree = {
  attributes: { token: string };
  metadata: { environment?: string };
  name: Vendor.braintree;
  preferred: boolean;
};
export type IApplePay = {
  name: Vendor.applepay;
  attributes: {
    payment_intent_client_secret: string;
  };
  metadata: {
    zip_required: boolean;
    address_required: boolean;
    email_required: boolean;
  };
  product_pricing: IPricing;
};
export type IGooglePay = {
  name: Vendor.googlepay;
  attributes: {
    payment_intent_client_secret: string;
  };
  metadata: {
    zip_required: boolean;
    address_required: boolean;
    email_required: boolean;
  };
  product_pricing: IPricing;
};
export type IPricing = {
  charge_amount: number;
  product_id: string;
  description: string;
  currency: string;
  stripe_amount?: number;
  price: string;
  tax: string;
  total: string;
};
export type ISavedCard = {
  brand: string;
  card_number: string;
  id: string;
};
type IAuthResult = {
  success: boolean;
  error: string;
  address_required: boolean;
  zip_required: boolean;
  email_required: boolean;
};

export type IPurchaseResponse = {
  auth_result: IAuthResult;
  custom_attributes_result: any;
  location_result: any;
  microsoft: any;
  player_data: {
    saved_cards?: ISavedCard[];
    receipt_email?: string | null;
  };
  product_pricing: IPricing;
  product_url: string;
  screen_result: any;
  vendors: (IStripe | IBraintree | IApplePay | IGooglePay)[];
  availability_result?: any;
};
export type IPurchase = IPurchaseResponse & {
  requestId: string;
  productId: string;
};
export type ITransactionData = {
  app_id: number;
  product_id: string;
  store: string;
  subscription: null;
  transaction_date: string;
  transaction_identifier: string;
};
export type ITransaction = {
  purchaserEmail?: string;
  payment_intent_client_secret: string;
  product: any;
  request_id: string;
  status: boolean;
  transaction: ITransactionData;
  transaction_signature: string;
};
export type IDevice = {
  device_id: string;
  form_factor: string;
  game_version: string;
  manufacturer: string;
  model: string;
  os: string;
  os_version: string;
  sdk_version: string;
  store: string;
};
