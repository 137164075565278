import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, Link, Navigate } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';

import { UserFunnelActions, Track } from '../helpers/Track';
import { getAppById, getUniversalLink, hirAppId, pokerAppId } from '../helpers/Utils';
import CONFIG, { UniversalLinkType } from '../helpers/Config';
import { NumberFormat } from '../helpers/formatter';
import { g, GAEvents } from '../helpers/GoogleAnalytics';
import { useAppState } from '../store/store';
import { IPlayer, IProductContent } from '../types';

import { Button } from './common/Buttons';

import mockIcon from '../images/placeholder-coin.png';
import { WrapContext } from '../App';
import { HIR_CURRENCY } from '../helpers/metaData/hir';
import PunchCard from './common/PunchCard';
import { POKER_CURRENCY } from '../helpers/metaData/poker';

type ReceiptProps = {
  syncStoreData: () => void;
};

const Receipt = (props: ReceiptProps) => {
  const navigate = useNavigate();
  const [txn] = useState(useLocation().state);

  const { state } = useAppState();
  const scrollToTop = useContext(WrapContext);
  const { auth, player } = state;
  const app = getAppById(Track.appId);
  const gameName = app?.displayName;
  const brand: string = (txn?.brand && CONFIG.brands[txn.brand]) || CONFIG.brands.unknown;

  const { name = '', game_player_id = '', balances, punch_card } = { ...player } as IPlayer;

  useEffect(() => {
    navigate('', { replace: true, state: null });
  }, []);

  useEffect(() => {
    let timer = scrollToTop();
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    // TODO: smarten this up so as not to repeat code
    if (txn && app) {
      Track.count(UserFunnelActions.payment_success);
      const primaryPurchase: IProductContent = txn.contents?.find((content) => content?.primary) || txn;

      const initialBalance = balances?.find((balance) => balance?.key === primaryPurchase?.key);
      const initialCurrencyBalance = initialBalance?.amount || '0';

      const newBalance = +initialCurrencyBalance + +primaryPurchase?.amount;

      const isPoker = app.parentAppId === pokerAppId || app.appId === pokerAppId;
      const isHIR = app.parentAppId === hirAppId || app.appId === hirAppId;

      if (isHIR || isPoker) {
        Track.batchCount([
          Track.makeEvent({
            ztrackEvent: 'count',
            counter: CONFIG.trackEvent,
            phylum: txn.productId,
            kingdom: UserFunnelActions.receipt,
            value: initialCurrencyBalance,
            family: primaryPurchase?.amount,
            attribute: newBalance.toString(),
            class: txn.id,
            client_id: CONFIG.trackClientId
          })
        ]);
      }
    }
  }, [txn]);

  const onShopAgain = () => {
    props.syncStoreData();
    g.sendEvent(GAEvents.btnClick, { type: 'Shop Again', appId: app?.appId });
  };

  const onReturnToGame = () => {
    const universalLink = getUniversalLink({
      appId: Track.appId,
      type: UniversalLinkType.close,
      appLoadId: Track.appLoadId
    });
    if (universalLink) {
      window.location.href = universalLink;
    } else {
      navigate({ pathname: '/error' });
    }
  };

  if (!txn) {
    return <Navigate to='../catalog' replace={true} />;
  }
  const showPunchCardDetails = () =>
    punch_card?.remaining_purchases === 0 || purchasesMade >= punch_card?.max_purchases!;

  const primaryPurchase: IProductContent = txn.contents?.find((content) => content?.primary) || txn;
  const additionalPurchase: IProductContent[] = txn.contents?.filter((content) => !content.primary);

  const initialBalance = balances?.find((balance) => balance?.key === primaryPurchase?.key);
  const initialCurrencyBalance = initialBalance?.amount || 0;

  const initialVipStamps = punch_card?.max_purchases! - punch_card?.remaining_purchases!;

  const additionalVipStampsPurchase: IProductContent = txn.contents?.find(
    (content) => content?.key === POKER_CURRENCY.stamps
  );

  const purchasesMade = +initialVipStamps + +additionalVipStampsPurchase?.amount!;
  const remainingPurchases = Math.abs(
    punch_card?.max_purchases! - (+initialVipStamps + +additionalVipStampsPurchase?.amount!)
  );

  const newBalance = showPunchCardDetails()
    ? NumberFormat(+punch_card?.reward_quantity! + +initialCurrencyBalance + +primaryPurchase?.amount)
    : NumberFormat(+initialCurrencyBalance + +primaryPurchase?.amount);

  return (
    <div className='receipt'>
      <div className='receipt-image-wrapper'>
        <div className='glow-bg' />
        <img src={txn.iconUrl || mockIcon} className='receipt-image' alt={t`product_icon`} />
      </div>

      <div className='purchase-complete'>
        <Trans>purchase_success_message</Trans>
      </div>
      <div className='receipt-content-wrapper'>
        <div className='gems-purchased'>
          <Trans id='purchased_item'>purchased_item {primaryPurchase?.name}</Trans>
        </div>
      </div>
      {additionContentSummary(gameName, additionalPurchase)}
      {player?.punch_card && showPunchCardDetails() && (
        <PunchCard
          punchCard={player?.punch_card}
          maxPurchases={punch_card?.max_purchases}
          purchasesMade={purchasesMade}
          remainingPurchases={remainingPurchases}
          classes='my-2 !important'
        />
      )}
      <div className={`purchase-details-wrapper ${showPunchCardDetails() ? '' : 'mt-12'}`}>
        <div className='purchase-details-wrapper-inner'>
          <div className='flex justify-between items-center purchase-detail'>
            <Trans id='starting_chip_balance'>Starting {initialBalance?.currency_name} Balance</Trans>
            <div>{NumberFormat(initialCurrencyBalance)}</div>
          </div>
          <div className='flex justify-between items-center purchase-detail'>
            <Trans>this_purchase</Trans>
            <div>{NumberFormat(primaryPurchase?.amount)}</div>
          </div>
          {showPunchCardDetails() && (
            <div className='flex justify-between items-center purchase-detail'>
              <Trans>vip_stamp_bonus</Trans>
              <div>{NumberFormat(punch_card?.reward_quantity!)}</div>
            </div>
          )}
          <div className='separator' />
          <div className='flex justify-between items-center purchase-detail'>
            <Trans id='new_currency_balance'>New {initialBalance?.currency_name} Balance</Trans>
            <div>{newBalance}</div>
          </div>
        </div>
      </div>
      <div className='receipt-outer'>
        <div className='receipt-inner'>
          {name && (
            <>
              <div className='receipt-label'>
                <Trans>player_label</Trans>
              </div>
              <div className='receipt-label receipt-value'>{name}</div>
            </>
          )}
          {game_player_id && (
            <>
              <div className='receipt-label'>
                <Trans>account_label</Trans>
              </div>
              <div className='receipt-label receipt-value'>{game_player_id}</div>
            </>
          )}
          {txn.purchaserEmail && (
            <>
              <div className='receipt-label'>
                <Trans>player_email</Trans>
              </div>
              <div className='receipt-label receipt-value'>{txn.purchaserEmail}</div>
            </>
          )}
          <div className='receipt-label'>
            <Trans>payment_type</Trans>
          </div>
          <div className='receipt-label receipt-value'>{brand}</div>
          <div className='receipt-label'>
            <Trans>product_price</Trans>
          </div>
          <div className='receipt-label receipt-value'>{txn.displayPrice}</div>
          {!game_player_id && (
            <>
              <div className='receipt-label'>
                <Trans>player_id</Trans>
              </div>
              <div className='receipt-label receipt-value'>{auth?.playerId}</div>
            </>
          )}
        </div>
        <div className='hidden receipt-outer-border zigzag' />
      </div>
      <div className='customer-support'>
        {/* prettier-ignore */}
        <Trans id='customer_support_footer'>
          For help with this purchase, contact <br /> <a href='https://www.zynga.com/support/' target='zyngaCS'> Zynga Customer Support</a>.
        </Trans>
      </div>
      <div className='text-center mt-4 cta-wrapper'>
        <div>
          <Button
            className='mt-5 btn-submit secondary'
            type='button'
            onClick={() => {
              Track.count(UserFunnelActions.success_to_app);
              g.sendEvent(GAEvents.btnClick, { type: 'Return to game', appId: app?.appId });
              onReturnToGame();
            }}
          >
            <Trans id='game_universal_link_btn'>game_universal_link_btn {gameName}</Trans>
          </Button>
        </div>
        <div className='py-3'>
          <Link to='../catalog' replace={true} onClick={onShopAgain}>
            <Button className='mt-3 btn-submit shop-again primary' type='button'>
              <Trans>make_another_purchase</Trans>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Receipt;

const additionContentSummary = (gameName: string | undefined, additionalPurchase: IProductContent[]) => {
  switch (gameName) {
    case CONFIG.appsName.poker:
      return (
        <div className='additional-purchase'>
          {additionalPurchase
            ?.filter((item) => Number(item?.amount) > 0)
            .map((item) => item?.name)
            .join(' + ')}
        </div>
      );
    case CONFIG.appsName.hit_it_rich:
      return (
        <div
          className={`additional-purchase ${
            additionalPurchase?.length === 3
              ? 'three-items-wrapper'
              : additionalPurchase?.length > 3
              ? 'multi-line-wrapper'
              : 'wrapper'
          }`}
        >
          {additionalPurchase.map((item) => (
            <div className='flex items-center additional-item' key={item.key}>
              <div className='item-image-wrapper'>
                <img src={item.icon_url} width={35} alt='icon' />
              </div>
              {additionalPurchase?.length > 2 ? (
                <div className='content-wrapper'>
                  <div className='item-name'>
                    {item.key === HIR_CURRENCY.card_pack || item.key === HIR_CURRENCY.pinata
                      ? item.name
                      : Number(item?.amount)}
                  </div>
                  {item.description && item.key !== HIR_CURRENCY.pinata && (
                    <div className='item-name'>{item.description}</div>
                  )}
                </div>
              ) : (
                <div className='item-name'>{item.name}</div>
              )}
            </div>
          ))}
        </div>
      );
    default:
      return null;
  }
};
