import { t } from '@lingui/macro';
import { useStripe, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { Stripe, StripePaymentRequestButtonElementOptions } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import Api from '../../../helpers/Api';
import { setLoading } from '../../../store/actions';
import { useAppState } from '../../../store/store';
import { IApplePay, IGooglePay, IItem, IPurchase, ITransaction, Vendor } from '../../../types';
import Spinner from '../../common/Spinner';

const applePayStyle = {
  // type: 'buy',
  theme: 'light-outline', // dark, light, light-outline
  height: '50px',
  borderRadius: '5px'
};

type IAppleOrGooglePayProps = {
  purchase: IPurchase;
  onComplete: (props: ITransaction & { vendor: string; brand: string }) => void;
  token: string;
  applePay?: IApplePay;
  googlePay?: IGooglePay;
  item: IItem;
  onCartError: (err?: string) => void;
};
type IVendor = (IApplePay | IGooglePay) & { type: Vendor };

const AppleOrGooglePay = ({
  purchase,
  token,
  applePay,
  googlePay,
  onComplete,
  item,
  onCartError
}: IAppleOrGooglePayProps) => {
  const { dispatch } = useAppState();

  const [hasVendor, setHasVendor] = useState<null | boolean>(null);

  const [paymentRequest, setPaymentRequest] = useState<null | PaymentRequest | any>(null);

  const [paymentsReady, setPaymentsReady] = useState(false);
  const stripe = useStripe();

  const completePurchase = (vendor: IVendor) => {
    if (!vendor) {
      return;
    }
    Api.makePurchase({
      productId: purchase.productId,
      requestId: purchase.requestId,
      vendor: vendor.type,
      token: token
    }).then(
      (purchaseResult) => {
        onComplete({
          ...purchaseResult,
          vendor: vendor.type,
          brand: vendor.type === Vendor.applepay ? 'applepay' : 'googlepay'
        });
        dispatch(setLoading(false));
      },
      (err) => {
        onCartError(t`error_generic`);
        dispatch(setLoading(false));
      }
    );
  };

  useEffect(() => {
    if (stripe && (applePay || googlePay) && !paymentRequest) {
      let vendor: IVendor | null;
      const applePaySecret = applePay?.attributes.payment_intent_client_secret;
      const googlePaySecret = googlePay?.attributes.payment_intent_client_secret;
      const currency =
        applePay?.product_pricing.currency.toLowerCase() || googlePay?.product_pricing.currency.toLowerCase();
      const stripe_amount = applePay?.product_pricing.stripe_amount || googlePay?.product_pricing.stripe_amount;
      if ((applePaySecret || googlePaySecret) && currency && stripe_amount) {
        const pr = stripe.paymentRequest({
          country: 'US',
          currency: currency,
          total: {
            label: item.name,
            amount: stripe_amount
          },
          requestPayerEmail: true,
          requestPayerName: true
        });
        pr.on('cancel', () => {
          dispatch(setLoading(false));
        });
        pr.on('paymentmethod', (ev) => {
          if (!vendor) {
            return;
          }
          onCartError(undefined);
          dispatch(setLoading(true));
          stripe
            .confirmCardPayment(
              vendor.attributes.payment_intent_client_secret,
              {
                payment_method: ev.paymentMethod.id,
                receipt_email: ev.paymentMethod.billing_details.email || undefined
              },
              { handleActions: false }
            )
            .then(
              ({ paymentIntent, error }) => {
                if (error) {
                  ev.complete('fail');
                  onCartError(t`error_generic`);
                  dispatch(setLoading(false));
                } else {
                  ev.complete('success');
                  if (paymentIntent?.status === 'requires_action') {
                    stripe
                      .confirmCardPayment(vendor!.attributes.payment_intent_client_secret, {
                        payment_method: ev.paymentMethod.id,
                        receipt_email: ev.paymentMethod.billing_details.email || undefined
                      })
                      .then((error) => {
                        if (error) {
                          onCartError(t`error_generic`);
                          dispatch(setLoading(false));
                        } else {
                          completePurchase(vendor!);
                        }
                      });
                  } else if (paymentIntent?.status === 'succeeded') {
                    completePurchase(vendor!);
                  }
                }
              },
              (s) => console.log(s)
            )
            .catch((err) => console.log(err));
        });
        pr.canMakePayment().then((res) => {
          if (res && res.applePay && applePay) {
            vendor = { ...applePay, type: Vendor.applepay };
            setHasVendor(true);
            setPaymentRequest(pr);
          } else if (res && res.googlePay && googlePay) {
            vendor = { ...googlePay, type: Vendor.googlepay };
            setHasVendor(true);
            setPaymentRequest(pr);
          } else {
            setHasVendor(false);
          }
        });
      }
    }
  }, [stripe]);

  return (
    <div>
      {hasVendor && (
        <div className='stripe-vendor-outer'>
          {paymentRequest && (
            <PaymentRequestButtonElement
              options={{ paymentRequest, style: { paymentRequestButton: applePayStyle as any } }}
              onClick={() => {
                dispatch(setLoading(true));
              }}
              onReady={() => {
                setPaymentsReady(true);
              }}
            />
          )}
        </div>
      )}
      {!paymentsReady && hasVendor !== false && (
        <div className='button-loading'>
          <Spinner show={!paymentsReady} local={true} />
        </div>
      )}
    </div>
  );
};
export default AppleOrGooglePay;
