import LocalStorage from './LocalStorage';

const makeLocaleKey = () => {
  return `locale`;
};

const I18nStorage = {
  get: () => {
    return LocalStorage.getItem(makeLocaleKey());
  },
  set: (data: string) => {
    return LocalStorage.setItem(makeLocaleKey(), data);
  }
};

export default I18nStorage;
