import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';

import CONFIG, { UniversalLinkType } from '../helpers/Config';
import {
  currentAppName,
  devLogger,
  getCoExistingApps,
  getUniversalLink,
  isCurrentAppAuthed,
  isCurrentAppPoker,
  setBodyClass
} from '../helpers/Utils';
import { Track } from '../helpers/Track';
import { IApp } from '../helpers/Apps';
import { g, GAEvents } from '../helpers/GoogleAnalytics';
import { useAppState } from '../store/store';

import { Button } from './common/Buttons';
import DialogBox from './common/DialogBox';
import { isIOS } from '../helpers/device';

const Landing = ({ app }: { app: IApp }) => {
  const { state } = useAppState();

  const navigate = useNavigate();

  const gameName = app?.displayName;
  const items = app?.items;

  setBodyClass('game-', app && [app?.pathname, app?.appId, app?.className]);

  const goToGame = () => {
    g.sendEvent(GAEvents.btnClick, { type: 'Connect to Zynga Game', appId: app?.appId });

    const universalLink = getUniversalLink({
      appId: app?.appId,
      type: UniversalLinkType.open,
      appLoadId: Track.appLoadId
    });

    if (isCurrentAppAuthed(app)) {
      navigate(app.pathname + '/catalog', { replace: true });
    } else if (universalLink) {
      window.location.href = universalLink;
      devLogger.log(universalLink);
    } else {
      navigate({ pathname: '/error' });
    }
  };

  const PokerCatalogItem = React.lazy(() => import('./Catalog/Poker/CatalogItem'));
  const EmpirePuzzleCatalogItem = React.lazy(() => import('./Catalog/EmpirePuzzles/CatalogItem'));
  const HitItRichCatalogItem = React.lazy(() => import('./Catalog/HitItRich/CatalogItem'));

  if (state.error) {
    return <DialogBox message={t({ id: state?.error?.message })} />;
  }

  if (!app) {
    g.sendEvent(GAEvents.landingPage);
    const coExistingApps = getCoExistingApps();
    return (
      <div className='mobile-landing-wrapper'>
        <div className='game-neutral-landing'>
          {coExistingApps?.map(
            (app) =>
              app.appId && (
                <div
                  className={`app-wrapper ${app.className}-landing-image-wrapper`}
                  key={app.appId}
                  onClick={() => navigate(`/${app.pathname}`)}
                >
                  <p>{app.displayName}</p>
                </div>
              )
          )}
          {coExistingApps?.length % 2 !== 0 && (
            <div className={`app-wrapper coming-soon-apps-landing-image-wrapper`}>
              <p>More Coming Soon !</p>
            </div>
          )}
        </div>
        <div className='generic-page-footer'>
          {/* prettier-ignore */}
          <Trans id='generic_page_footer'>The official store of Zynga games.<br />Only at store.zynga.com <br/>©Zynga, Inc {CONFIG.currentYear}</Trans>
        </div>
      </div>
    );
  }

  g.sendEvent(GAEvents.gameLandingPage, { appId: app?.appId });
  return (
    <div className={`landing ${app.className}`}>
      <div className='landing-wrapper'>
        <p className='landing-title'>
          <Trans id={`${app.i18nPrefix}_landing_title`}></Trans>
        </p>
        <div className='landing-message-wrapper'>
          <p className='landing-message'>
            <Trans id={`${app.i18nPrefix}_landing_subtitle`}></Trans>
          </p>
        </div>
        <ul className='item-list'>
          {items?.map((item, i) =>
            (() => {
              if (currentAppName(app) === CONFIG.appsName.poker) {
                return <PokerCatalogItem key={i} item={item} app={app} onAddToCart={goToGame} />;
              } else if (currentAppName(app) === CONFIG.appsName.empire_and_puzzle) {
                return <EmpirePuzzleCatalogItem key={i} item={item} app={app} onAddToCart={goToGame} />;
              } else return <HitItRichCatalogItem key={i} item={item} onAddToCart={goToGame} />;
            })()
          )}
        </ul>
        <div className='free-coin'>
          <Trans id='landing_free_coin_text' />
        </div>
        <div className='landing-button-wrapper'>
          <Button size='lg' onClick={goToGame} className='landing-button secondary'>
            <Trans id={`${app.i18nPrefix}_connect_button`}></Trans>
          </Button>
        </div>
        <div className='footer'>
          {/* prettier-ignore */}
          <Trans id='footer'>The official store of {gameName}<span className='hidden exclaimation'>!</span><br />Only at store.zynga.com</Trans>
        </div>
      </div>
    </div>
  );
};
export default Landing;
