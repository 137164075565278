import React, { useEffect, useRef, useState } from 'react';
import { Trans } from '@lingui/macro';
import QRCode from 'qrcode.react';

import {
  dragonCityAppId,
  empirePuzzleAppId,
  getCoExistingApps,
  getStoreLink,
  isCurrentAppPoker,
  mergeDragonsAppId,
  setBodyClass,
  wozSlotsAppId,
  wwfAppId
} from '../helpers/Utils';
import { Track } from '../helpers/Track';
import { IApp } from '../helpers/Apps';

import mobileScreen from '../images/landing/desktop/mobile-screen.png';
import qrArrow from '../images/landing/desktop/qr-arrow.svg';
import { Icon } from '@iconify/react';
import CONFIG from '../helpers/Config';

type DesktopHomeProps = {
  app: IApp;
};

const DesktopHome = ({ app }: DesktopHomeProps) => {
  const [isLeftArrowActive, setIsLeftArrowActive] = useState(false);
  const [isRightArrowActive, setIsRightArrowActive] = useState(false);
  const [isLeftArrowScrollActive, setIsLeftArrowScrollActive] = useState(false);
  const [isRightArrowScrollActive, setIsRightArrowScrollActive] = useState(false);

  const storeLink = (app: IApp) => {
    return getStoreLink({
      appId: app?.appId,
      appLoadId: Track.appLoadId
    });
  };
  setBodyClass('game-', app && [app?.appId, app?.className]);
  const gameName = app?.displayName;
  const displayName = app?.displayName;
  const coExistingApps = getCoExistingApps();

  const isEmpires = (app: IApp): boolean => app.parentAppId === empirePuzzleAppId || app.appId === empirePuzzleAppId;

  const isWozSlots = (app: IApp): boolean => app?.parentAppId === wozSlotsAppId || app?.appId === wozSlotsAppId;

  const isWWF = (app: IApp): boolean => app?.parentAppId === wwfAppId || app?.appId === wwfAppId;

  const isMergeDragons = (app: IApp): boolean =>
    app?.parentAppId === mergeDragonsAppId || app?.appId === mergeDragonsAppId;

  const isDragonCity = (app: IApp): boolean => app?.parentAppId === dragonCityAppId || app?.appId === dragonCityAppId;

  useEffect(() => {
    const tabItem = document.getElementById('game-carousel');
    const setArrowsActive = () => {
      let width = Math.floor(tabItem?.offsetWidth!);
      let scrollWidth = Math.floor(tabItem?.scrollWidth!);
      let scrollLeft = Math.floor(tabItem?.scrollLeft!);
      if (scrollWidth - width >= scrollLeft) setIsLeftArrowActive(true);
      if (scrollWidth - width === scrollLeft) setIsRightArrowActive(false);
      if (scrollLeft === 0) {
        setIsLeftArrowActive(false);
        setIsRightArrowActive(true);
      }
    };

    setArrowsActive();
    // @ts-ignore
    if (tabItem) tabItem.onscroll = () => setArrowsActive();
  }, []);

  const onLeftScroll = () => {
    const tabItem = document.getElementById('game-carousel');
    let leftPos = tabItem?.scrollLeft!;
    document.getElementById('game-carousel')?.scrollTo({ left: leftPos - 700, behavior: 'smooth' });
  };

  const onRightScroll = () => {
    const tabItem = document.getElementById('game-carousel');
    let leftPos = tabItem?.scrollLeft!;
    document.getElementById('game-carousel')?.scrollTo({ left: leftPos + 700, behavior: 'smooth' });
  };

  return (
    <div className={`desktop-home-wrapper`}>
      {app ? (
        <div className='max-w-screen-xl w-full flex justify-center items-end desktop-home-content'>
          <div className='flex text-container mr-24'>
            <div>
              <div className='game-logo' />
              <div className='game-name mt-12 mb-8'>{displayName}</div>
              <div className='game-qr-text'>
                {isCurrentAppPoker(app) ? (
                  <>
                    {/* prettier-ignore */}
                    <Trans id='desktop_qr_scan_poker'>Trying to connect to the Zynga Store?<br />You are on an unsupported device. Scan this QR code with a supported iOS, Android, or Kindle device with Zynga Poker installed in order to reach the Zynga Store.</Trans>
                  </>
                ) : (
                  <>
                    {/* prettier-ignore */}
                    <Trans id='desktop_qr_scan'>Connect to the Zynga Store<br />Scan the QR code with the device you use to play the game</Trans>
                  </>
                )}
              </div>
              <div className='desktop-home-footer mt-20'>
                {/* prettier-ignore */}
                <Trans id='desktop_screen_footer'>The official store of {gameName}<span className='hidden exclaimation'>!</span><br />Only on store.zynga.com. All Rights Reserved {CONFIG.currentYear}</Trans>
              </div>
            </div>
            <img src={qrArrow} alt='->' className='qr-arrow' />
          </div>
          <div className='qr-screen-wrapper'>
            <img src={mobileScreen} alt='screen' className='qr-screen' />
            <div className='qr-wrapper'>
              <span className='qr-frame'>
                <QRCode value={storeLink(app)} size={250} className='qr-code' />
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className='game-neutral'>
          <div className='apps-wrapper' id='game-carousel'>
            {coExistingApps?.map(
              (appItem) =>
                appItem.appId && (
                  <div className={`desktop-home-content-wrapper`} key={appItem.appId}>
                    <div className={`${appItem.className} desktop-home-content`}>
                      <div className='text-container'>
                        <div className='appname'>{appItem.displayName}</div>
                        <div className='relative w-full h-full'>
                          <div className='qr-text-container'>
                            <div className='game-qr-text'>
                              {/* prettier-ignore */}
                              <Trans id='desktop_qr_scan'>Connect to the Zynga Store<br />Scan the QR code with the device you use to play the game</Trans>
                            </div>
                            <div className='qr-screen-wrapper'>
                              <div className='qr-wrapper'>
                                <span className='qr-frame'>
                                  {appItem?.storeUrl ? (
                                    <QRCode value={appItem.storeUrl} size={150} className='qr-code' />
                                  ) : (
                                    <QRCode value={storeLink(appItem)} size={150} className='qr-code' />
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
          <div className='scrolling-container'>
            <button
              className={`scroll ${isLeftArrowActive ? 'active-scroll' : 'cursor-not-allowed'}`}
              id='left-arrow'
              onClick={() => onLeftScroll()}
              disabled={!isLeftArrowActive}
              onMouseOver={() => setIsLeftArrowScrollActive(true)}
              onMouseOut={() => setIsLeftArrowScrollActive(false)}
            >
              <Icon
                icon='typcn:arrow-left-thick'
                color={isLeftArrowScrollActive && isLeftArrowActive ? '#ffffff' : '#333333'}
                width={30}
              />
            </button>
            <button
              className={`scroll ml-1 ${isRightArrowActive ? 'active-scroll' : 'cursor-not-allowed'}`}
              id='right-arrow'
              onClick={() => onRightScroll()}
              disabled={!isRightArrowActive}
              onMouseOver={() => setIsRightArrowScrollActive(true)}
              onMouseOut={() => setIsRightArrowScrollActive(false)}
            >
              <Icon
                icon='typcn:arrow-right-thick'
                color={isRightArrowScrollActive && isRightArrowActive ? '#ffffff' : '#333333'}
                width={30}
              />
            </button>
          </div>

          <div className='desktop-home-footer-game-neutral'>
            {/* prettier-ignore */}
            <Trans id='desktop_screen_footer_game_neutral'>The official store of Zynga games. Only at store.zynga.com <br />©Zynga, Inc {CONFIG.currentYear}</Trans>
          </div>
        </div>
      )}
    </div>
  );
};

export default DesktopHome;
