import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';

import { isMobileOrTablet, isSupportedDevice } from '../helpers/device';
import CONFIG, { UniversalLinkType } from '../helpers/Config';
import { getAppFromPath, getCoExistingApps } from '../helpers/Utils';
import { g, GAEvents } from '../helpers/GoogleAnalytics';

import Landing from '../views/Landing';
import Home from '../views/Home';
import DesktopHome from '../views/DesktopHome';
import DialogBox from './common/DialogBox';

const LandingWrapper = () => {
  const { pathname } = useLocation();

  const path = pathname.split('/')[1];
  const path2 = pathname.split('/')[2];
  let app = getAppFromPath(path) as any;
  let app2 = getAppFromPath(path2) as any;

  const supportedAppIds = CONFIG.apps.map((app) => app.appId);
  const supportedAppPathNames = CONFIG.apps.map((app) => app.pathname);

  const doesPath1ContainsApp = () => supportedAppIds.includes(path) || supportedAppPathNames.includes(path);
  const doesPath2ContainsApp = () => supportedAppIds.includes(path2) || supportedAppPathNames.includes(path2);

  const coExistingApps = getCoExistingApps() || [];

  if (pathname === '/' && coExistingApps.length === 1) {
    return <Navigate to={`/${coExistingApps[0].appId}`} replace />;
  }

  if (path === 'error' || path2 === 'error') {
    return <DialogBox message={t({ id: CONFIG.errors.generic_error })} />;
  } else if (!isMobileOrTablet()) {
    return <DesktopHome app={app} />;
  } else if (!isSupportedDevice()) {
    return (
      <Home
        app={app || app2}
        message={t({
          id: 'unsupported_device_error',
          message: 'The Zynga Store is currently available on iOS and Android devices.'
        })}
      />
    );
  } else if (path === CONFIG.universalLink[UniversalLinkType.open] && doesPath2ContainsApp()) {
    app = getAppFromPath(path2);
    g.sendEvent(GAEvents.installGamePage, { appId: app?.appId });
    return (
      <Home
        app={app}
        message={t({
          id: 'app_not_installed',
          message: `${app.displayName} must be installed on this device to access the Zynga Store`
        })}
        showDownloadButton={true}
      />
    );
  } else if (path === CONFIG.universalLink[UniversalLinkType.close] && doesPath2ContainsApp()) {
    app = getAppFromPath(path2);
    return (
      <Home
        app={app}
        message={t({
          id: 'return_error',
          message: 'Oh no!  Something went wrong returning to your game.'
        })}
        showDownloadButton={true}
      />
    );
  } else if (path2 === 'coming-soon' && doesPath1ContainsApp()) {
    app = getAppFromPath(path);
    g.sendEvent(GAEvents.comingSoonPage, { appId: app?.appId });
    return (
      <Home
        app={app}
        message={
          <div>
            <Trans id='coming_soon_msg'>
              The {app.displayName} store is currently in Beta.
              <br />
              Wide release is coming soon!
            </Trans>
          </div>
        }
      />
    );
  } else if (doesPath1ContainsApp() || pathname === '/') {
    return <Landing app={app} />;
  } else {
    return <DialogBox message={t({ id: CONFIG.errors.generic_error })} />;
  }
};

export default LandingWrapper;
