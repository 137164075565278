import React, { useRef, useState } from 'react';
import { useElements, useStripe, CardExpiryElement, CardCvcElement, CardNumberElement } from '@stripe/react-stripe-js';
import {
  Stripe,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  PaymentMethodResult,
  PaymentIntentResult,
  StripeElementStyle
} from '@stripe/stripe-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faMapMarkerAlt, faCalendarWeek, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { t, Trans } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';

import Api from '../../../helpers/Api';
import { IPurchase, ISavedCard, ITransaction, Vendor } from '../../../types/Purchase';
import { useAppState } from '../../../store/store';
import { setLoading } from '../../../store/actions';
import { IApiError } from '../../../helpers/Ajax';
import { IItem } from '../../../types/Catalog';
import Log from '../../../helpers/Log';
import { getAppById, HandleError, isEmailValid } from '../../../helpers/Utils';
import { g, GAEvents } from '../../../helpers/GoogleAnalytics';

import { Button } from '../../common/Buttons';
import SavedCards from './SavedCards';
import {
  DeleteCards,
  IStripeElementPuchaseAgreementStyle,
  NewCard,
  StripeElements as el,
  STRIPE_ERRORS
} from './CreditCardMetadata';
import CONFIG from '../../../helpers/Config';

type ICreditCardFormProps = {
  purchase: IPurchase;
  onComplete: (props: ITransaction & { vendor: string; brand: string }) => void;
  token: string;
  showEmail?: boolean;
  showSavedCards?: boolean;
  handleOFCAErrors: (props: { purchase: IPurchase; cart: IItem }) => void;
  syncStoreData: (udpateBalanceOnly?: boolean) => Promise<boolean>;
  cardElementsStyle: StripeElementStyle & IStripeElementPuchaseAgreementStyle;
  showDeleteCard: (show: boolean) => void;
  onCartError: (err?: string) => void;
};

const CreditCardForm = ({
  purchase,
  onComplete,
  token,
  showEmail,
  showSavedCards,
  handleOFCAErrors,
  syncStoreData,
  showDeleteCard,
  cardElementsStyle,
  onCartError
}: ICreditCardFormProps) => {
  const { state, dispatch } = useAppState();
  const navigate = useNavigate();
  const { auth, player } = state;
  const app = getAppById(auth!.appId);
  const stripe = useStripe();
  const elements = useElements();
  const submitButton = useRef<HTMLButtonElement>(null);
  const zipElement = useRef<HTMLInputElement>(null);
  const [showDelete, setShowDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [inputError, setInputError] = useState<string>('');
  const [rememberMe, setRememberMe] = useState(true);
  const [euConsent, setEUConsent] = useState(false);

  const defaultCard =
    purchase.player_data.saved_cards && purchase.player_data.saved_cards[0] && showSavedCards
      ? purchase.player_data.saved_cards[0].id
      : NewCard;

  const [selectedCard, setSelectedCard] = useState(defaultCard);
  const [stripeReady, setStripeReady] = useState({
    [el.card]: false,
    [el.exp]: false,
    [el.cvc]: false
  });
  const isStripeReady = (): boolean => {
    return stripeReady[el.card] && stripeReady[el.exp] && stripeReady[el.cvc];
  };
  const [stripeComplete, setStripeComplete] = useState({
    [el.card]: false,
    [el.exp]: false,
    [el.cvc]: false
  });
  const isStripeComplete = (): boolean => {
    return stripeComplete[el.card] && stripeComplete[el.exp] && stripeComplete[el.cvc];
  };
  const [stripeValid, setStripeValid] = useState({
    [el.card]: true,
    [el.exp]: true,
    [el.cvc]: true
  });
  const isStripeValid = (): boolean => {
    return stripeValid[el.card] && stripeValid[el.exp] && stripeValid[el.cvc];
  };
  const [stripeErrors, setStripeErrors] = useState({ [el.card]: '', [el.exp]: '', [el.cvc]: '' });

  const getStripeErrors = (): string[] => {
    return Object.keys(stripeErrors)
      .map((x) => stripeErrors[x])
      .filter((x) => !!x);
  };
  const handleError = (error: IApiError) => {
    if (auth?.appId) {
      HandleError({ error, appId: auth.appId, navigate, dispatch });
      dispatch(setLoading(false));
    }
  };

  const [postalCode, setPostalCode] = useState<string>('');
  const [email, setEmail] = useState<string>(purchase.player_data.receipt_email || '');
  const [savedCards, setSavedCards] = useState(purchase.player_data?.saved_cards);
  const [deleteCardIds, setDeleteCardIds] = useState<string[]>([]);

  // if the purchase call fails we need to generate a new requestId to try again
  const reinitilizePurchase = (purchase: IPurchase) => {
    if (auth) {
      return Api.initPurchase({
        locale: auth.locale,
        currency: auth.currency,
        token: auth.token,
        productId: purchase.productId,
        countryOfPurchase: auth.countryOfPurchase
      }).then((purchase) => {
        handleOFCAErrors({ purchase, cart: state.cart as IItem });
      }, handleError);
    }
    return Promise.reject();
  };

  const createPaymentMethodFailure = (err: IApiError) => {
    const stripeError = STRIPE_ERRORS.find((x) => x.code === err.category);

    const message = stripeError
      ? `${t({ id: stripeError.message })} (${stripeError.code})`
      : `${t({ id: 'error_generic' })}`;
    Log.error('STRIPE createPaymentMethod Error\n', err);
    onCartError(message);
    dispatch(setLoading(false));
  };
  // TODO hash out how the errors should be surfaced or if they are session ending
  const purchaseFailure = (err: IApiError) => {
    const stripeError = STRIPE_ERRORS.find((x) => x.code === err.category);

    const message = stripeError
      ? `${t({ id: stripeError.message })} (${stripeError.code})`
      : `${t({ id: 'error_generic' })}`;

    reinitilizePurchase(purchase)
      .then(() => {
        Log.error('STRIPE Puchase Error\n', err);
        onCartError(message);
      }, handleError)
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const isEmailRequired = purchase.auth_result.email_required;
  const isZipCodeRequired = purchase.auth_result.zip_required;

  const inputEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    setInputError('');
    setEmail(evt.target.value);
  };
  const validateEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    if (!isEmailRequired) {
      return;
    }
    if (!isEmailValid(evt.target.value)) {
      //TODO: i18n
      setInputError('Your email address is invalid');
    } else {
      setInputError('');
    }
  };
  const inputPostalCode = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    setPostalCode(evt.target.value);
  };
  const stripeError = (stripeResponse: PaymentMethodResult | PaymentIntentResult) => {
    dispatch(setLoading(false));
    onCartError(stripeResponse.error?.message);
    Log.error('Stripe error', stripeResponse);
  };

  type IPurchaseRequest = {
    token: string;
    productId: string;
    requestId: string;
    saveCard: boolean;
    vendor: Vendor;
    email?: string;
    cardToCharge?: string;
    paymentSourceId?: string;
  };

  const handlePurchase = (purchaseRequest: IPurchaseRequest, brand: string) => {
    Api.makePurchase(purchaseRequest).then((purchaseRes) => {
      if (purchaseRes.status) {
        const purchase = { purchaserEmail: purchaseRequest.email, ...purchaseRes };
        onComplete({ ...purchase, vendor: Vendor.stripe, brand });
      } else if (purchaseRes.payment_intent_client_secret) {
        (stripe as Stripe).handleCardAction(purchaseRes.payment_intent_client_secret).then((intent) => {
          if (!intent.error) {
            Api.makePurchase({ ...purchaseRequest }).then((finalPurchase) => {
              if (finalPurchase.status) {
                const completePurchase = { purchaserEmail: purchaseRequest.email, ...finalPurchase };
                onComplete({ ...completePurchase, vendor: Vendor.stripe, brand });
              } else {
                // TODO need to figure out what this means to the user
                purchaseFailure({
                  url: '',
                  status: 999
                });
              }
            }, purchaseFailure);
          } else {
            stripeError(intent);
          }
        }, purchaseFailure);
      }
    }, purchaseFailure);
  };
  // TODO add error handling
  const handleDeleteCards = () => {
    dispatch(setLoading(true));
    Api.deleteSavedCards({ cardIds: deleteCardIds, token })
      .then((cards: ISavedCard[]) => {
        setSavedCards(cards);
        setDeleteCardIds([]);
        showDeleteCard(false);
        setShowDelete(false);
        if (cards.length === 0) {
          setSelectedCard(NewCard);
        } else {
          setSelectedCard(cards[0].id);
        }
      }, handleError)
      .finally(() => dispatch(setLoading(false)));
  };

  const handleSubmit = async (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    g.sendEvent(GAEvents.btnClick, { type: 'Attempting Purchase', appId: auth?.appId });

    if (!canSubmit()) {
      return false;
    }
    await syncStoreData(true);

    onCartError(undefined);
    if (selectedCard === DeleteCards && deleteCardIds.length > 0) {
      handleDeleteCards();
    } else {
      if (elements && stripe) {
        dispatch(setLoading(true));
        const basePurchase: IPurchaseRequest = {
          token,
          productId: purchase.productId,
          requestId: purchase.requestId,
          saveCard: rememberMe && !!showSavedCards,
          vendor: Vendor.stripe
        };
        const cardToCharge = savedCards?.find((card) => card.id === selectedCard);
        if (cardToCharge) {
          const { brand } = cardToCharge;
          basePurchase.cardToCharge = selectedCard;
          basePurchase.saveCard = false;
          handlePurchase(basePurchase, brand);
        } else {
          const card = elements.getElement(CardNumberElement) as StripeCardNumberElement;
          const billing_details: { [key: string]: any } = {};
          if (email) {
            billing_details.email = email;
          }
          if (postalCode) {
            billing_details.address = { postal_code: postalCode };
          }
          stripe
            .createPaymentMethod({
              type: 'card',
              card,
              billing_details: billing_details
            })
            .then((res) => {
              if (res && res.paymentMethod) {
                if (res.paymentMethod.billing_details?.email) {
                  basePurchase.email = res.paymentMethod.billing_details?.email;
                }
                basePurchase.paymentSourceId = res.paymentMethod.id;
                handlePurchase(basePurchase, res.paymentMethod.card?.brand || 'creditcard');
              } else if (res.error) {
                stripeError(res);
              }
            }, createPaymentMethodFailure);
        }
      }
    }
  };
  /**
   * When a field has an error set error.  When field is complete set complete.
   * When complete tab to next field.
   */
  const stripeInput = (
    evt: StripeCardNumberElementChangeEvent | StripeCardCvcElementChangeEvent | StripeCardExpiryElementChangeEvent
  ) => {
    if (evt.complete) {
      setStripeValid({ ...stripeValid, [evt.elementType]: true });
      setStripeErrors({ ...stripeErrors, [evt.elementType]: '' });
      setStripeComplete({ ...stripeComplete, [evt.elementType]: true });
      switch (evt.elementType) {
        case el.card.toString():
          elements?.getElement(el.exp)?.focus();
          break;
        case el.exp:
          elements?.getElement(el.cvc)?.focus();
          break;
        case el.cvc:
          if (isZipCodeRequired && zipElement?.current) {
            zipElement.current.focus();
          } else if (submitButton?.current) {
            submitButton.current.focus();
          }
          break;
      }
    } else if (evt.error) {
      setStripeComplete({ ...stripeComplete, [evt.elementType]: false });
      setStripeValid({ ...stripeValid, [evt.elementType]: false });
      setStripeErrors({ ...stripeErrors, [evt.elementType]: evt.error.message });
    } else {
      setStripeErrors({ ...stripeErrors, [evt.elementType]: '' });
      setStripeComplete({ ...stripeComplete, [evt.elementType]: false });
    }
  };

  const canSubmit = () => {
    return (
      isStripeReady() &&
      // stripe form is valid and new card
      ((isStripeComplete() && isStripeValid() && selectedCard === NewCard) ||
        // a saved card is selected
        (selectedCard !== NewCard && selectedCard !== DeleteCards) ||
        // one or more cards are to be deleted
        (selectedCard === DeleteCards && deleteCardIds.length > 0)) &&
      (isEmailRequired ? isEmailValid(email) : true) &&
      (!CONFIG.europeCountriesCode.includes(player?.tax_country!) ||
        (CONFIG.europeCountriesCode.includes(player?.tax_country!) && euConsent))
    );
  };
  return (
    <div className='credit-card-form cc-outer'>
      <form
        onSubmit={handleSubmit}
        className={`cc-form ${showEmail ? 'has-email' : ''} ${isZipCodeRequired ? 'has-zip' : ''} ${
          CONFIG.europeCountriesCode.includes(player?.tax_country!) ? 'has-consent' : ''
        } ${selectedCard === NewCard ? 'new-card' : ''}`}
      >
        {showSavedCards && savedCards?.length ? (
          <div className='saved-cards'>
            <SavedCards
              savedCards={savedCards}
              selectedCard={selectedCard}
              deleteCardIds={deleteCardIds}
              setDeleteCardIds={setDeleteCardIds}
              setSelectedCard={setSelectedCard}
              showDeleteCard={(show: boolean) => {
                setShowDelete(show);
                showDeleteCard(show);
              }}
            />
            {CONFIG.europeCountriesCode.includes(player?.tax_country!) && selectedCard !== NewCard && !showDelete && (
              <div className='eu-consent'>
                <label>
                  <input
                    type='checkbox'
                    className='inline mr-2 '
                    checked={euConsent}
                    onChange={() => {
                      setEUConsent(!euConsent);
                    }}
                  />
                  <Trans id='eu_consent_text'>
                    I consent to this purchase being immediately added to my account and understand that I will lose my
                    right to cancel my purchase after I claim it in-game.
                  </Trans>
                </label>
              </div>
            )}
          </div>
        ) : (
          ''
        )}

        <div className={selectedCard !== NewCard ? 'hidden' : ''}>
          {showEmail && (
            <div className='email-wrapper'>
              <FontAwesomeIcon
                icon={faEnvelope}
                className={`fa-icon ${!isStripeReady() && 'fa-disabled'}`}
                style={{ fontSize: 16 }}
              />
              <input
                type='email'
                className={`email-field ${inputError ? 'invalid-field' : ''}`}
                required={isEmailRequired}
                value={email}
                onChange={inputEmail}
                onBlur={validateEmail}
                disabled={!isStripeReady()}
                placeholder={`Email address ${isEmailRequired ? '(required)' : '(optional for receipt)'}`}
              />
            </div>
          )}

          <div className='grid grid-cols-1 mt-4'>
            <div className='credit-card-field border  rounded-t h-11 pt-3 pr-4 border-b-0 relative pl-10'>
              <FontAwesomeIcon
                icon={faCreditCard}
                style={{ fontSize: 16 }}
                className={`fa-icon ${!stripeValid[el.card] && 'fa-invalid'} ${!isStripeReady() && 'fa-disabled'}`}
              />
              <CardNumberElement
                onChange={stripeInput}
                onReady={() => {
                  setStripeReady((prevState) => ({ ...prevState, [el.card]: true }));
                }}
                options={{
                  disabled: !isStripeReady(),
                  style: cardElementsStyle,
                  placeholder: 'Card Number'
                }}
              />
            </div>
          </div>
          <div className='grid grid-cols-2'>
            <div className='stripe-field card-expiry'>
              <FontAwesomeIcon
                icon={faCalendarWeek}
                style={{ fontSize: 18 }}
                className={`fa-icon ${!stripeValid[el.exp] && 'fa-invalid'} ${!isStripeReady() && 'fa-disabled'}`}
              />
              <CardExpiryElement
                onChange={stripeInput}
                onReady={() => {
                  setStripeReady((prevState) => ({ ...prevState, [el.exp]: true }));
                }}
                options={{
                  disabled: !isStripeReady(),
                  style: cardElementsStyle
                }}
              />
            </div>
            <div className='stripe-field card-cvc'>
              <FontAwesomeIcon
                icon={faLock}
                style={{ fontSize: 18 }}
                className={`fa-icon ${!stripeValid[el.cvc] && 'fa-invalid'} ${!isStripeReady() && 'fa-disabled'}`}
              />
              <CardCvcElement
                onChange={stripeInput}
                onReady={() => {
                  setStripeReady((prevState) => ({ ...prevState, [el.cvc]: true }));
                }}
                options={{
                  disabled: !isStripeReady(),
                  style: cardElementsStyle
                }}
              />
            </div>
          </div>
          {CONFIG.europeCountriesCode.includes(player?.tax_country!) && (
            <div
              className='eu-consent'
              style={{
                backgroundColor: cardElementsStyle.base?.backgroundColor,
                color: cardElementsStyle.textColor,
                borderColor: cardElementsStyle.borderColor
              }}
            >
              <label>
                <input
                  type='checkbox'
                  className='inline mr-2 '
                  checked={euConsent}
                  onChange={() => {
                    setEUConsent(!euConsent);
                  }}
                />
                <Trans id='eu_consent_text'>
                  I consent to this purchase being immediately added to my account and understand that I will lose my
                  right to cancel my purchase after I claim it in-game.
                </Trans>
              </label>
            </div>
          )}

          {isZipCodeRequired && (
            <div className='relative grid grid-cols-1 h-11 border-t-0 rounded-t-none rounded-b '>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ fontSize: 18 }}
                className={`fa-icon ${!isStripeReady() && 'fa-disabled'}`}
              />
              <input
                type='text'
                ref={zipElement}
                required={isZipCodeRequired}
                value={postalCode}
                onChange={inputPostalCode}
                disabled={!isStripeReady()}
                className='postal-code rounded border-t-0'
                placeholder={'Zip Code'}
              />
            </div>
          )}
          {showSavedCards && (
            <div className='my-3 remember-me'>
              <label>
                <input
                  type='checkbox'
                  className='inline mr-2'
                  checked={rememberMe}
                  onChange={() => {
                    setRememberMe(!rememberMe);
                  }}
                />
                <Trans>remember_me</Trans>
              </label>
            </div>
          )}
        </div>
        {/* {errorMessage && <div className='py-2 text-left card-error'>{errorMessage}</div>} */}
        {inputError && <div className='py-2 text-left card-error'>{inputError}</div>}
        {getStripeErrors().length > 0 && (
          <div className='py-2 text-left card-error'>
            {getStripeErrors().map((err, i) => {
              return <div key={i}>{err}</div>;
            })}
          </div>
        )}
        <div className={`purchase-agreement ${showDelete ? 'hidden' : ''}`}>
          {/* prettier-ignore */}
          <Trans id='purchase_agreement_text'>
          By clicking Pay Now, you confirm that you have read, understand and agree to be bound by 
          <u><a href='https://www.take2games.com/privacy/' target='_blank' rel='noreferrer'>Zynga’s Privacy Policy</a></u> and 
          <u><a href='https://www.zynga.com/legal/terms-of-service' target='_blank' rel='noreferrer'>Terms of Service</a></u>. 
          All rules regarding your order can be found in our <u><a href={CONFIG.refundPolicyUrl} target='_blank' rel='noreferrer'>Refund Policy</a></u>. 
          Please note that the merchant name on your payment method statement will read as "ZYNGA - {app?.displayName}".
         </Trans>
        </div>
        <Button type='submit' className='mt-4 my-2 btn-submit secondary' ref={submitButton} disabled={!canSubmit()}>
          {selectedCard === DeleteCards ? <Trans>Save Changes</Trans> : <Trans>complete_purchase_btn</Trans>}
        </Button>
        {showDelete && (
          <Button
            className='mt-3 btn-cancel'
            onClick={() => {
              setSelectedCard(defaultCard);
              setShowDelete(false);
              showDeleteCard(false);
            }}
          >
            <Trans>cancel_btn</Trans>
          </Button>
        )}
      </form>
    </div>
  );
};

export default CreditCardForm;
