import React, { useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { ISavedCard } from '../../../types';
import { DeleteCards, NewCard } from './CreditCardMetadata';

type SavedCardsProps = {
  selectedCard: string;
  deleteCardIds: string[];
  savedCards: ISavedCard[];
  setSelectedCard: (id: string) => void;
  setDeleteCardIds: (ids: string[]) => void;
  showDeleteCard: (show: boolean) => void;
};

const SavedCards = (props: SavedCardsProps) => {
  const { selectedCard, deleteCardIds, savedCards, setSelectedCard, setDeleteCardIds } = props;
  const [showMenu, setShowMenu] = useState(false);
  const cardMenu = useRef<HTMLUListElement>(null);
  const onSelectCard = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: string) => {
    ev.preventDefault();
    setShowMenu(false);
    props.showDeleteCard(value === DeleteCards);
    setSelectedCard(value);
  };
  const currentCard = () => {
    if (selectedCard === DeleteCards) {
      return t`Delete Card(s)`;
    } else if (selectedCard === NewCard) {
      return (
        <div className='card-action'>
          <span className='brand brand-new'>
            <FontAwesomeIcon icon={faPlusCircle} />
          </span>
          <Trans>Add New Card</Trans>
        </div>
      );
    } else {
      const card = savedCards.find((c) => c.id === selectedCard);
      return card ? (
        <>
          <span className={`brand brand-${card.brand}`}></span>
          {`**** ${card.card_number}`}
        </>
      ) : (
        ''
      );
    }
  };
  return (
    <>
      <div>
        <button
          className={`saved-cards-trigger ${selectedCard === DeleteCards ? 'force-hide' : ''}`}
          onClick={(e) => {
            setShowMenu(true);
            e.preventDefault();
          }}
        >
          {currentCard()}{' '}
          <span className='ml-auto'>
            <FontAwesomeIcon icon={faChevronDown} />
          </span>
        </button>
        {showMenu && (
          <div
            onClick={(e) => {
              setShowMenu(false);
              e.preventDefault();
            }}
            className='card-menu-bkg'
          />
        )}
        <ul className={`saved-cards-menu ${showMenu ? 'saved-cards-show' : 'saved-cards-hide'}`} ref={cardMenu}>
          {savedCards.map((card) => (
            <li key={card.id}>
              <button
                onClick={(e) => {
                  onSelectCard(e, card.id);
                }}
              >
                <span className={`brand brand-${card.brand}`}></span>
                {`**** ${card.card_number}`}
              </button>
            </li>
          ))}
          <li className='card-action'>
            <button
              onClick={(e) => {
                onSelectCard(e, DeleteCards);
              }}
            >
              <span className='brand brand-delete'>
                <FontAwesomeIcon icon={faMinusCircle} />
              </span>
              {`Delete Card(s)`}
            </button>
          </li>
          <li className='card-action'>
            <button
              onClick={(e) => {
                onSelectCard(e, NewCard);
              }}
            >
              <span className='brand brand-new'>
                <FontAwesomeIcon icon={faPlusCircle} />
              </span>
              {`Add New Card`}
            </button>
          </li>
        </ul>
      </div>
      {selectedCard === DeleteCards && (
        <div>
          <ul className=' delete-cards'>
            {savedCards.map((card) => (
              <li key={card.id} className=''>
                <label className=''>
                  <span className={`brand brand-${card.brand}`}> </span>
                  <span className='text-right'>**** {card.card_number}</span>
                  <span className='h-full ml-auto'>
                    <span className='mr-2'>Delete</span>
                    <input
                      type='checkbox'
                      value={card.id}
                      checked={deleteCardIds.includes(card.id)}
                      onChange={() => {
                        setDeleteCardIds(
                          deleteCardIds.includes(card.id)
                            ? deleteCardIds.filter((x) => x !== card.id)
                            : deleteCardIds.concat(card.id)
                        );
                      }}
                    />
                  </span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default SavedCards;
