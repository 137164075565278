import CONFIG from './Config';
import { generateAppLoadId, generateServerAppLoadId, uuid, userAgentData, devLogger } from './Utils';

/**
 * Docs: https://servicehub.zynga.com/services/track#apis-events
 */

const trackUrl = 'https://api.zynga.com/track/events';

export enum UserFunnelActions {
  catalog = 'catalog',
  checkout = 'checkout',
  receipt = 'receipt',
  package_details = 'package_details',
  payment_selection = 'payment_selection',
  payment_success = 'payment_success',
  success_to_app = 'success_to_app',
  error_undefined = 'error_undefined',
  log_out = 'log_out',
  purchase = 'purchase',
  free_gift = 'free_gift'
}

export enum CounterName {
  catalog = 'catalog',
  zstore = 'count.zynga_store_funnel'
}

const ZTrackEvent = 'count.zynga_store_funnel.1.0.0';

// count settings/values for the event
export type IEvent = {
  ztrackEvent: string;
  counter: string;
  clientDeviceTs: number;
  kingdom: UserFunnelActions | string;
  genus?: string;
  class?: string;
  family?: string;
  phylum?: string;
  attribute?: string;
  value?: string;
  currency?: string;
  currency_flow?: string;
  client_id?: string;
  game_id?: string;
  amount?: string;
  total_amount?: string;
  value2?: string;
  value3?: string;
};

type IBatch = {
  batchUuid: string;
  records: IEvent[];
};
type ITrackRequest = {
  appLoadId: string;
  clientId: number;
  playerId: number;
  gameId: number;
  batches: IBatch[];
};
type ITrackConfig = {
  playerId?: number;
  appId?: number;
  token?: string;
  locale?: string;
  appVersion?: string;
};
class TRACK_SERVICE {
  private _appLoadId: string;
  private _serverAppLoadId: string;
  private Headers: Headers = new Headers();
  private config: ITrackConfig = {};
  private clientId: number = CONFIG.clientId;

  constructor() {
    // generate a fresh appLoadId on load
    this._appLoadId = generateAppLoadId();
    this._serverAppLoadId = generateServerAppLoadId(this._appLoadId);
    this.Headers.set('content-type', 'application/json');
  }
  get appLoadId() {
    return this._appLoadId;
  }
  set appLoadId(appLoadId: string) {
    this._appLoadId = appLoadId;
    this._serverAppLoadId = generateServerAppLoadId(appLoadId);
  }
  get serverAppLoadId() {
    return this._serverAppLoadId;
  }
  get appId() {
    return this.config.appId as number;
  }
  init({
    appId,
    playerId,
    token,
    locale,
    appLoadId,
    appVersion
  }: {
    appId: number | string;
    playerId: number | string;
    token: string;
    locale: string;
    appLoadId?: string;
    appVersion?: string;
  }) {
    this.config = { appId: Number(appId), playerId: Number(playerId), token, locale, appVersion };
    // overwrite appLoadId if passed
    this.appLoadId = appLoadId || this.appLoadId;
    this.Headers.set('authorization', this.config.token as string);
    // TODO: decide if this is needed or just chaff
    // this.userAgent();
  }
  clear(appLoadId?: string) {
    this.config = {};
    this.Headers.delete('authorization');
    this.appLoadId = appLoadId || generateAppLoadId();
  }

  userAgent() {
    const device = { ...userAgentData };
    const event = {
      counter: 'user_agent',
      ztrackEvent: 'count.user_agent.1.0.0',
      genus: device.ua,
      class: device.os.name,
      family: this.config.locale,
      kingdom: device.browser.name || 'unknown',
      phylum: device.browser.version,
      clientDeviceTs: Math.floor(Date.now() / 1000)
    };
    this.logCount(this.makeRequest(event));
    return true;
  }

  // Record count of event "action"
  count(action: UserFunnelActions): boolean {
    if (!this.config.appId || !this.config.token || !this.config.playerId) {
      return false;
    }
    const event = this.makeEvent({ kingdom: action });
    this.logCount(this.makeRequest(event));
    return true;
  }

  batchCount(events: IEvent[]): boolean {
    if (!this.config.appId || !this.config.token || !this.config.playerId) {
      return false;
    }
    this.logCount(this.makeRequest(events));
    return true;
  }

  makeEvent(event: Partial<IEvent> & { kingdom: UserFunnelActions }): IEvent {
    const counter = event.counter || CounterName.zstore;
    return {
      ztrackEvent: ZTrackEvent,
      clientDeviceTs: Math.floor(Date.now() / 1000),
      counter,
      ...event
    };
  }

  private logCount(payload: ITrackRequest) {
    devLogger.log(payload.batches[0].records[0].counter, payload.batches[0].records[0].kingdom);
    if (!this.clientId || !this.config.playerId || !this.config.token) {
      return false;
    }
    const Req = {
      method: 'POST',
      headers: this.Headers,
      body: JSON.stringify(payload)
    } as RequestInit;

    fetch(trackUrl, Req)
      .then((res) => res.json())
      .then((res) => {})
      .catch(() => {});
  }

  private makeBatch(eventStream: IEvent | IEvent[]): IBatch {
    return {
      batchUuid: uuid(),
      records: Array.isArray(eventStream) ? eventStream : [eventStream]
    };
  }

  private makeRequest(eventStream: IEvent | IEvent[]): ITrackRequest {
    return {
      gameId: this.config.appId as number,
      clientId: this.clientId,
      playerId: this.config.playerId as number,
      appLoadId: this.appLoadId,
      batches: [this.makeBatch(eventStream)]
    };
  }
}

export const Track = new TRACK_SERVICE();
