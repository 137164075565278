import CONFIG from './Config';
import Ajax, { IApiError } from './Ajax';
import { IAuth, IDevice, ICustomProduct, IPlayer, IPurchase, IPurchaseResponse, Vendor } from '../types';
import { getDeviceData, uuid } from './Utils';
import { Track } from './Track';

const iapUrl = CONFIG.iapHost + CONFIG.iapPath;

const appIdCode = ':appId';
const playerIdCode = ':playerId';
const localeIdCode = ':locale';
const currencyIdCode = ':currency';
const countryCode = ':country';

const urls = {
  initPurchase: iapUrl + '/transactions/initialize',
  makePurchase: iapUrl + '/purchases',
  deleteSavedCards: iapUrl + '/cards',
  createAccount: CONFIG.iapHost + process.env.REACT_APP_ZIS_URL + `/${appIdCode}/account/create/v2`,
  refreshToken: CONFIG.iapHost + process.env.REACT_APP_ZIS_URL + `/${appIdCode}/token/refresh/v2`,
  initStore:
    iapUrl +
    `/player_info/${appIdCode}/${playerIdCode}?store=ZYNGA_STORE&locale=${localeIdCode}&landing_page=false&currency=${currencyIdCode}&country_of_purchase=${countryCode}`
};

const replaceParams = (
  url: string,
  params: { appId?: string; playerId?: string | number; locale?: string; currency?: string; countryOfPurchase?: string }
): string => {
  let ret = url;
  if (params.appId) {
    ret = ret.replace(new RegExp(appIdCode, 'g'), params.appId);
  }
  if (params.playerId) {
    ret = ret.replace(new RegExp(playerIdCode, 'g'), `${params.playerId}`);
  }
  if (params.locale) {
    ret = ret.replace(new RegExp(localeIdCode, 'g'), params.locale);
  }
  if (params.currency) {
    ret = ret.replace(new RegExp(currencyIdCode, 'g'), params.currency);
  }
  ret = ret.replace(new RegExp(countryCode, 'g'), params.countryOfPurchase || '');
  return ret;
};

type IMakePurchaseProps = {
  productId: string;
  requestId: string;
  token: string;
  saveCard?: boolean;
  vendor: Vendor;
  email?: string;
  cardToCharge?: string;
  paymentSourceId?: string;
  braintreeNonce?: string;
};
type IPurchaseRequest = {
  device: IDevice;
  payment_source_type: string;
  product_id: string;
  request_id: string;
  save_card: boolean;
  client_id: number;
  receipt_email?: string;
  vendor: Vendor;
  payment_source_id?: string;
  braintree_nonce?: string;
  card_to_charge?: string;
};

type IInitStoreResponse = {
  player_information: IPlayer;
  products: ICustomProduct[];
  stripe: {
    pub_key: string;
  };
};

const initStore = ({ currency, playerId, appId, locale, token, countryOfPurchase }: IAuth) => {
  const url = replaceParams(urls.initStore, { appId, currency, locale, playerId, countryOfPurchase });
  const options = {
    method: 'GET',
    headers: {
      authorization: token,
      'content-type': 'application/json',
      'zt-app-load-id': Track?.serverAppLoadId,
      'app-id': Track?.appId?.toString()
    }
  };
  return Ajax(url, options).then(
    (res: IInitStoreResponse) => {
      // sorting based on key: primary, to keep primary item at top
      res.products?.map((product) => product.contents?.sort((x: any, y: any) => y.primary - x.primary));
      return {
        playerInformation: res.player_information,
        products: res.products,
        stripeKey: res.stripe.pub_key
      };
    },
    (rej: IApiError) => {
      return Promise.reject(rej);
    }
  );
};

const initPurchase = ({
  locale,
  currency,
  productId,
  token,
  countryOfPurchase
}: {
  locale: string;
  currency: string;
  productId: string;
  token: string;
  countryOfPurchase: string;
}) => {
  const url = urls.initPurchase;
  const requestId = uuid();
  const data = {
    client_id: CONFIG.clientId,
    custom_attributes: {
      forced_email: false,
      forced_review: false,
      forced_zip_code: false
    },
    locale,
    localized_currency: currency,
    product_id: productId,
    request_date: new Date().toISOString(),
    request_id: requestId,
    store: CONFIG.zyngaStore,
    country_of_purchase: countryOfPurchase,
    vendors: Object.values(Vendor)
  };
  const options = {
    method: 'POST',
    headers: {
      authorization: token,
      'content-type': 'application/json',
      'zt-app-load-id': Track.serverAppLoadId,
      'app-id': Track.appId.toString()
    },
    body: JSON.stringify(data)
  };
  return Ajax(url, options).then(
    (res) => {
      return { ...(res as IPurchaseResponse), requestId, productId } as IPurchase;
    },
    (rej: IApiError) => {
      return Promise.reject(rej);
    }
  );
};

const makePurchase = ({
  productId,
  requestId,
  vendor,
  token,
  email,
  saveCard,
  paymentSourceId,
  cardToCharge,
  braintreeNonce
}: IMakePurchaseProps) => {
  const url = urls.makePurchase;
  /**
   * https://servicehub.zynga.com/services/iap#apis-DirectPayPurchase
   */
  const device = getDeviceData();

  const data: IPurchaseRequest = {
    device,
    payment_source_type: 'PaymentMethod',
    product_id: productId,
    request_id: requestId,
    client_id: CONFIG.clientId,
    save_card: !!saveCard,
    vendor: vendor
  };
  if (paymentSourceId) {
    data.payment_source_id = paymentSourceId;
  } else if (cardToCharge) {
    data.card_to_charge = cardToCharge;
  } else if (braintreeNonce) {
    data.braintree_nonce = braintreeNonce;
  }
  if (email) {
    data.receipt_email = email;
  }
  return Ajax(url, {
    method: 'POST',
    headers: {
      authorization: token,
      'content-type': 'application/json',
      'zt-app-load-id': Track.appLoadId,
      'app-id': Track.appId.toString()
    },
    body: JSON.stringify(data)
  }).then(
    (res) => res,
    (rej: IApiError) => Promise.reject(rej)
  );
};

type ISessionResponse = {
  appId: number;
  networkId: number;
  playerId: number;
  zid: number;
  expiration: number;
};

const checkAuth = (
  token: string
): Promise<{
  playerId: number;
  zid: number;
  expiration: number;
}> => {
  if (!token) {
    return Promise.reject();
  }
  const url = 'https://api.zynga.com/session';
  const options = {
    headers: {
      authorization: token
    }
  };
  return Ajax(url, options)
    .then(
      (res: ISessionResponse) => res,
      (rej: IApiError) => {
        return Promise.reject(rej);
      }
    )
    .catch((rej: IApiError) => Promise.reject(rej));
};

type IGeolocation = {
  areaCode: string;
  city: string;
  countryCode: string;
  ip: string;
  latitude: string;
  longitude: string;
  metroCode: string;
  postalCode: string;
  region: string;
  isEEA?: boolean;
  isEU?: boolean;
};
/**
 * Use the Location Service to get the user's geolocation from the ip address in their header.
 * https://servicehub.zynga.com/services/location#apis-ip
 *
 */
const getGeoLocation = (): Promise<IGeolocation | null> => {
  const url = 'https://api.zynga.com/location/ip';
  return Ajax(url).then(
    (res) => {
      return res as IGeolocation;
    },
    () => {
      return null;
    }
  );
};
type IDeleteSavedCardsProps = {
  cardIds: string[];
  token: string;
};
const deleteSavedCards = ({ cardIds, token }: IDeleteSavedCardsProps) => {
  const url = urls.deleteSavedCards;
  const data = { cards_to_delete: cardIds };

  return Ajax(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      authorization: token,
      'content-type': 'application/json',
      'zt-app-load-id': Track.serverAppLoadId,
      'app-id': Track.appId.toString()
    }
  }).then(
    (res) => res.active_cards,
    (rej) => Promise.reject(rej)
  );
};

const createAccount = (appId: string) => {
  const url = replaceParams(urls.createAccount, { appId });
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({})
  };
  return Ajax(url, options)
    .then(
      (res) => res.data,
      (rej: IApiError) => {
        return Promise.reject(rej);
      }
    )
    .catch((rej: IApiError) => Promise.reject(rej));
};

const refreshToken = ({
  appId,
  token,
  installId,
  installSecret
}: {
  appId: string;
  token: string;
  installId: string;
  installSecret: string;
}) => {
  const url = replaceParams(urls.refreshToken, { appId });
  const body = {
    token,
    install_credentials: {
      id: installId,
      secret: installSecret
    }
  };
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({ ...body })
  };
  return Ajax(url, options)
    .then(
      (res) => res.data,
      (rej: IApiError) => {
        return Promise.reject(rej);
      }
    )
    .catch((rej: IApiError) => Promise.reject(rej));
};

const Api = {
  initPurchase,
  makePurchase,
  checkAuth,
  getGeoLocation,
  deleteSavedCards,
  createAccount,
  refreshToken,
  initStore
};
export default Api;
