import { StripeElementStyle as StripeEleStyle } from '@stripe/stripe-js';

export const NewCard = 'new';
export const DeleteCards = 'delete';

export enum StripeElements {
  card = 'cardNumber',
  exp = 'cardExpiry',
  cvc = 'cardCvc'
}

export const STRIPE_ERRORS = [
  {
    code: 1035,
    type: 'INVALID_PARAMS_PASSED',
    message: `error_generic`
  },
  {
    code: 1117,
    type: 'STRIPE_BAD_REQUEST',
    message: `error_generic`
  },
  {
    code: 1002,
    type: 'NOT_AUTHORIZE',
    message: `error_not_authorised`
  },
  {
    code: 1115,
    type: 'STRIPE_CARD_DECLINED',
    message: `stripe_error_card_declined`
  },
  {
    code: 1021,
    type: 'BAD_REQUEST',
    message: `error_generic`
  },
  {
    code: 1000,
    type: 'GENERIC_EXCEPTION',
    message: `error_generic`
  },
  {
    code: 1120,
    type: 'STRIPE_GENERIC_ERROR',
    message: `error_generic`
  },
  {
    code: 1116,
    type: 'STRIPE_TOO_MANY_REQUESTS',
    message: `error_generic`
  }
];

export type IStripeElementPuchaseAgreementStyle = {
  textColor: string;
  borderColor: string;
};

export const StripeElementStyle: StripeEleStyle & IStripeElementPuchaseAgreementStyle = {
  base: {
    fontSize: '16px',
    fontFamily: 'Helvetica Neue',
    fontWeight: '700',
    lineHeight: '16px',
    backgroundColor: '#fff',
    '::placeholder': {
      color: 'rgba(0,0,0,0.5)'
    }
  },
  invalid: {
    color: '#f2a44a'
  },
  textColor: 'rgba(0,0,0,0.5)',
  borderColor: '#cccccc'
};

export const HIRStripeElementStyle: StripeEleStyle & IStripeElementPuchaseAgreementStyle = {
  base: {
    fontSize: '16px',
    fontFamily: 'Helvetica Neue',
    fontWeight: '600',
    lineHeight: '21px',
    color: '#fff',
    backgroundColor: '#1C0226',
    ':-webkit-autofill': {
      color: '#fff'
    },
    '::placeholder': {
      color: 'rgba(255, 255, 255,0.6)'
    }
  },
  invalid: {
    color: '#f2a44a'
  },
  textColor: 'rgba(255, 255, 255,0.6)',
  borderColor: 'rgba(255, 255, 255, 0.4)'
};
