import React, { useState, useEffect, useContext } from 'react';
import { Stripe } from '@stripe/stripe-js';
import { Link, useNavigate } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faLock } from '@fortawesome/free-solid-svg-icons';

import { IItem, IPurchase, ITransaction, IStripe, IBraintree, Vendor, IPricing, IApplePay, IGooglePay } from '../types';
import { useAppState } from '../store/store';
import { setLoading } from '../store/actions';
import Api from '../helpers/Api';
import { UserFunnelActions, Track } from '../helpers/Track';
import { currentAppName, getAppById, getMarketingMsgClass, HandleError, hirAppId } from '../helpers/Utils';
import { IApiError } from '../helpers/Ajax';
import { g, GAEvents } from '../helpers/GoogleAnalytics';
import CONFIG from '../helpers/Config';
import { HIR_CURRENCY } from '../helpers/metaData/hir';

import { HIRStripeElementStyle, StripeElementStyle } from './Stripe/CreditCard/CreditCardMetadata';
import { WrapContext } from '../App';
import { Button } from './common/Buttons';
import PlayerInfo from './Player/PlayerInfo';
import GameBalance from './Player/GameBalance';
import StripeComponent from './Stripe';
import PayPal from './PayPal';

import mockIcon from '../images/placeholder-coin.png';
import ccLogos from '../images/icons/cc-logos.png';

type ICartProps = {
  cart: IItem | null;
  purchase: IPurchase | null;
  locale: string | null;
  token: string | null;
  stripePromise: Promise<Stripe | null>;
  onComplete: (props: ITransaction & { vendor: string; brand: string; pricing?: IPricing }) => void;
  handleOFCAErrors: (props: { purchase: IPurchase; cart: IItem }) => void;
  syncStoreData: (udpateBalanceOnly?: boolean) => Promise<boolean>;
};

const Cart = ({ purchase, cart, ...props }: ICartProps) => {
  const { state, dispatch } = useAppState();
  const [showDelete, setShowDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { auth, player } = state;
  const app = getAppById(auth!.appId);
  const scrollToTop = useContext(WrapContext);
  const navigate = useNavigate();
  const braintree =
    CONFIG.paypalCurrencies.indexOf(auth!.currency) > -1 &&
    (purchase?.vendors?.find((v) => v.name === Vendor.braintree) as IBraintree | undefined);
  const applePay = purchase?.vendors?.find((v) => v.name === Vendor.applepay) as IApplePay | undefined;
  const googlePay = purchase?.vendors?.find((v) => v.name === Vendor.googlepay) as IGooglePay | undefined;
  const stripe = purchase?.vendors?.find((v) => v.name === Vendor.stripe) as IStripe | undefined;
  const [showCC, setShowCC] = useState(false);

  const handleError = (error: IApiError) => {
    if (auth?.appId) {
      HandleError({ error, appId: auth?.appId, navigate, dispatch });
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    let timer = scrollToTop();
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    Track.count(UserFunnelActions.checkout);
    const isHIR = app?.parentAppId === hirAppId || app?.appId === hirAppId;

    if (isHIR) {
      const wallet = player?.balances?.find((curr) => curr.key === HIR_CURRENCY.coins)?.amount;
      const coinsAmount = cart?.contents?.find((curr) => curr.primary)?.amount;
      Track.batchCount([
        Track.makeEvent({
          counter: CONFIG.trackEvent,
          kingdom: UserFunnelActions.checkout,
          class: cart?.product_id,
          family: coinsAmount,
          value: wallet,
          ztrackEvent: 'count'
        })
      ]);
    }
  }, []);

  const onPaypalPayment = (nonce: string) => {
    dispatch(setLoading(true));
    Api.makePurchase({
      braintreeNonce: nonce,
      vendor: Vendor.braintree,
      productId: purchase!.productId,
      requestId: purchase!.requestId,
      token: props.token!
    }).then((purchaseRes) => {
      props.onComplete({
        ...purchaseRes,
        vendor: Vendor.braintree,
        brand: 'paypal',
        pricing: purchase?.product_pricing
      });
    }, handleError);
  };

  const onCreditCardComplete = (txn: ITransaction & { vendor: string; brand: string }) => {
    props.onComplete({ ...txn, pricing: purchase?.product_pricing });
  };

  const showDeleteCard = (show: boolean) => {
    setShowDelete(show);
  };

  return (
    <>
      {player?.balances?.length ? <GameBalance /> : <PlayerInfo />}
      <div className={`cart ${cart?.marketing_message ? 'limited-sales' : ''}`}>
        <div className='cart-title'>
          {showDelete ? (
            <Trans>Delete Card</Trans>
          ) : (
            <>
              <FontAwesomeIcon icon={faLock} className='fa-icon' />
              <Trans>cart_title</Trans>
            </>
          )}
        </div>
        <div>
          {cart && purchase ? (
            <div className='text-center'>
              {!showDelete && (
                <>
                  <div className='cart-item'>
                    {cart?.marketing_message && (
                      <div className={`bonus-sale marketing-msg ${getMarketingMsgClass(cart.marketing_message)}`}>
                        <p>{cart.marketing_message}</p>
                      </div>
                    )}
                    <div className='cart-item-inner'>
                      <div className='cart-image-wrapper'>
                        <img src={cart.icon_url || mockIcon} className='cart-image' alt='cart' />
                      </div>
                      <div className='cart-details'>
                        <h3 className='cart-name'>{cart.name}</h3>
                        <h4 className='cart-description'>{cart.description}</h4>
                        <div className='cart-price'>{purchase.product_pricing.total}</div>
                      </div>
                      <div className='cart-item-contents-wrapper'>
                        {cart.contents?.map((contentItem) =>
                          (() =>
                            Number(contentItem.amount) > 0 && (
                              <div className='content-item' key={contentItem.key}>
                                <div className='content-item-image-wrapper'>
                                  <img src={contentItem.icon_url} width={18} alt='icon' />
                                </div>
                                <div className='content-item-name'>{contentItem.name}</div>
                              </div>
                            ))()
                        )}
                      </div>
                    </div>
                  </div>
                  {purchase.product_pricing.description && (
                    <div className='cart-tax'>{purchase.product_pricing.description}</div>
                  )}
                  {errorMessage && <div className='cart-error'>{errorMessage}</div>}
                </>
              )}

              {stripe && (
                <>
                  <div className='block text-center'>
                    <StripeComponent
                      purchase={purchase}
                      stripePromise={props.stripePromise}
                      onComplete={onCreditCardComplete}
                      token={props.token as string}
                      locale={props.locale as string}
                      showSavedCards={true}
                      showEmail={true}
                      handleOFCAErrors={props.handleOFCAErrors}
                      syncStoreData={props.syncStoreData}
                      showDeleteCard={showDeleteCard}
                      cardElementsStyle={
                        currentAppName(app) === CONFIG.appsName.hit_it_rich ? HIRStripeElementStyle : StripeElementStyle
                      }
                      showCC={showCC}
                      applePay={applePay}
                      googlePay={googlePay}
                      item={cart}
                      onCartError={(d) => {
                        setErrorMessage(d);
                      }}
                    />
                    {showCC && (braintree || applePay) && !showDelete && (
                      <Button
                        className='mt-3 btn-cancel'
                        onClick={() => {
                          setShowCC(false);
                          setErrorMessage(undefined);
                        }}
                      >
                        <Trans>cancel_btn</Trans>
                      </Button>
                    )}
                  </div>
                </>
              )}
              {braintree ? (
                <div className={showCC ? 'hidden h-0 w-0 overflow-hidden' : ''}>
                  <div className='paypal-container'>
                    <PayPal
                      onPaypalPayment={onPaypalPayment}
                      pricing={purchase.product_pricing}
                      authorization={braintree && braintree.attributes.token}
                      onCartError={(d) => {
                        setErrorMessage(d);
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {showCC || (!braintree && !applePay) ? null : (
                <div className='text-center mb-4'>
                  <Button
                    onClick={() => {
                      g.sendEvent(GAEvents.btnClick, { type: 'Pay with Credit Card', appId: auth?.appId });
                      setShowCC(true);
                      setErrorMessage(undefined);
                    }}
                    className='btn-submit cc'
                    size='lg'
                  >
                    <FontAwesomeIcon icon={faCreditCard} size='lg' className='fa-cc' />
                    {/* I18N */}
                    <div className='credit-card'>Credit Card</div>
                  </Button>
                  <img src={ccLogos} className='mx-auto mb-10 mt-2' />
                </div>
              )}
            </div>
          ) : (
            <h2>
              <Trans>empty_cart</Trans>
            </h2>
          )}
        </div>
        {!showCC && !showDelete && (
          <Link to='../catalog' className='block text-center'>
            <Button className='btn-cancel'>
              <Trans>back_btn</Trans>
            </Button>
          </Link>
        )}
        <div className='customer-support top-full'>
          {/* prettier-ignore */}
          <Trans id='customer_support_footer'>
        For help with this purchase, contact <br /> <a href='https://www.zynga.com/support/' target='zyngaCS'> Zynga Customer Support</a>.
        </Trans>
        </div>
      </div>
    </>
  );
};
export default Cart;
