import React from 'react';
import { Trans } from '@lingui/macro';

import { useAppState } from '../../store/store';
import { IPlayer } from '../../types';

const PlayerInfo = () => {
  const { player } = useAppState().state;
  const { name = '', game_player_id = '' } = { ...player } as IPlayer;

  if (!name && !game_player_id) {
    return null;
  }

  return (
    <div className='player-info-wrapper'>
      <div className='max-w-screen-xl mx-auto flex justify-between p-3 sm:p-4 font-helvetica text-sm'>
        <div>
          <Trans id='player'>player {name}</Trans>
        </div>
        <div>
          <Trans id='account'>account {game_player_id}</Trans>
        </div>
      </div>
    </div>
  );
};

export default PlayerInfo;
