export const GAEvents = {
  btnClick: 'button_clicked',
  pageLoad: 'page_load',
  landingPage: 'landing_page',
  gameLandingPage: 'game_landing_page',
  installGamePage: 'install_game_page',
  comingSoonPage: 'coming_soon_page',
  authSuccess: 'auth_success',
  authFailure: 'auth_failure'
};

class G {
  timer: ReturnType<typeof setTimeout> | null = null;
  sendEvent(event: string, params = {}) {
    if (!window.gtag) {
      this.timer = setTimeout(() => this.sendEvent(event, params), 1000);
    } else {
      this.timer && clearTimeout(this.timer);
      window.gtag('event', event, { ...params, env: process.env.REACT_APP_ENV });
    }
  }
  setGA(key: string, value: { [key: string]: any }) {
    window.gtag('set', key, value);
  }
}
export const g = new G();
